






















import {Prop} from "vue-property-decorator";
import {Component, Vue} from '@/shared/component';

import storeTiers from '@/stores/modules/tiers';

import CrudList from '@/components/Crud/List.vue';
import TableHead from '@/components/Paginate/TableHead.vue';
import TableCell from '@/components/Paginate/TableCell.vue';

@Component({
	components: {
		CrudList,
		TableHead,
		TableCell,
	}
})
export default class CrudTiersListForSelect extends Vue {
	
	@Prop({type: Boolean, default: true  }) private paginate;
	@Prop({type: Boolean, default: false }) private dense;
	
	private storeTiers = storeTiers;
}
