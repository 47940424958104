import {Contrat} from '@/models';

export default [
	{
		path: '/contrat',
		name: 'contrat',
		meta: {
			title: 'Liste des contrats',
			granted: [ 'API_GET_CONTRAT' ]
		},
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/Contrat.vue')
	},
	{
		path: '/contrat/show/:id',
		name: 'contrat-show',
		props: {
			slotData: () => ({
				component: import(/* webpackChunkName: "dashboard" */ '../components/Crud/Contrat/Show.vue'),
				class: Contrat
			}),
			editRoute: { name: 'contrat-edit' },
		},
		meta: {
			title: 'Détails d\'un contrat',
			granted: [ 'API_GET_CONTRAT' ]
		},
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/EditShow.vue')
	},
	{
		path: '/contrat/edit/:id',
		name: 'contrat-edit',
		props: {
			slotData: () => ({
				component: import(/* webpackChunkName: "dashboard" */ '../components/Crud/Contrat/Edit.vue'),
				class: Contrat
			}),
		},
		meta: {
			title: 'Edition d\'un contrat',
			granted: [ 'API_PUT_CONTRAT' ]
		},
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/EditShow.vue')
	},
];