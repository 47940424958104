import {XHTTP} from '@/shared/xhttp';
import {AbatteurService} from '@/xhttp';

@XHTTP(AbatteurService, '/abatteurs')
export class Abatteur {
	
	private _id: number;
	private _firstName: string;
	private _lastName: string;
	private _email: string;
	private _phone1: string;
	private _phone2: string;
	private _createdAt: Date;
	
	/////////////
	// Getters //
	/////////////
	
	public get id(): number {
		return this._id;
	}
	
	public get firstName(): string {
		return this._firstName;
	}

	public get lastName(): string {
		return this._lastName;
	}

	public get fullName(): string {
		return (this.firstName.trim()+' '+this.lastName.trim()).trim();
	}
	
	public get email(): string {
		return this._email;
	}
	
	public get phone1(): string {
		return this._phone1;
	}
	
	public get phone2(): string {
		return this._phone2;
	}
	
	public get createdAt(): Date {
		return this._createdAt;
	}

	/////////////
	// Setters //
	/////////////
	
	public set firstName(value: string) {
		this._firstName = value;
	}
	
	public set lastName(value: string) {
		this._lastName = value;
	}
	
	public set email(value: string) {
		this._email = value;
	}
	
	public set phone1(value: string) {
		this._phone1 = value;
	}
	
	public set phone2(value: string) {
		this._phone2 = value;
	}
	
	public set createdAt(value: Date) {
		this._createdAt = value;
	}
	
	///////////////
	// Serialize //
	///////////////

	public toJSON(): any {
		return {
			id       : this._id,
			firstName: this._firstName,
			lastName : this._lastName,
			email    : this._email,
			phone1   : this._phone1,
			phone2   : this._phone2,
		};
	}

	public fromJSON(data: any): this {
		this._id        = data.id;
		this._firstName = data.firstName;
		this._lastName  = data.lastName;
		this._email     = data.email;
		this._phone1    = data.phone1;
		this._phone2    = data.phone2;
		this._createdAt = data.createdAt ? new Date(data.createdAt) : null;
		return this;
	}
}
