export class Native {

	public static defined(obj): boolean {
		return typeof obj !== 'undefined';
	}

	public static empty(value: any): boolean {
		return value === null || typeof value === 'undefined' || value === '';
	}

	public static toInt(value): number {
		if (value === null || !this.defined(value)) {
			return null;
		}
		return parseInt(value.toString(), 10);
	}

	public static toFloat(value): number {
		if (value === null || !this.defined(value)) {
			return null;
		}
		return parseFloat(value.toString());
	}

	public static toBoolean(value): boolean {
		return !(!value || value === '0' || value.toString().toLowerCase() === 'false');
	}

	public static isInt(value): boolean {
		return !isNaN(parseInt(value, 10));
	}

	public static isBoolean(value): boolean {
		return typeof value == 'boolean';
	}

	public static callSetter (clazz: any, property: string, bind: any, value: any): void {
		Object.getOwnPropertyDescriptor(clazz.prototype, property).set.call(bind, value);
	}

	public static callGetter (clazz: any, property: string, bind: any): any {
		return Object.getOwnPropertyDescriptor(clazz.prototype, property).get.call(bind);
	}
}
