







































import {Emit} from 'vue-property-decorator';
import {InOut} from 'vue-inout-prop-decorator';
import {Component, Vue} from '@/shared/component';

import {Tiers, TiersType} from '@/models';

import CrudTiersEdit from '@/components/Crud/Tiers/Edit.vue';

@Component({
	components: {
		CrudTiersEdit,
	}
})
export default class CrudTiersEditDialog extends Vue {
	@InOut({ type: Tiers  , isVModel: true }) private value!: Tiers;
	@InOut({ type: Boolean  , isVModel: false, default: true }) private Redirect: Boolean;

	TiersType = TiersType;

	@Emit()
	private onSubmit(tiers: Tiers) {
		this.onClose();
	}

	@Emit()
	private onClose() {
		if (this.Redirect == true)
		{
			if (this.value.id) 
			{
				if (
					this.$route.name !== 'tiers-show' || 
					this.$route.params["id"] !== this.value.id.toString()
				) {
					this.$router.push({name: 'tiers-show', params: { id : this.value.id.toString() }});
				}
			}
		}
		this.value = null;
	}
}
