
















import {Component} from '@/shared/component';
import {AbstractSearchVue} from '@/components/Abstract/AbstractSearchVue';

import InputSearchEntity from '@/components/Input/SearchEntity.vue';
import CrudContratFilter from '@/components/Crud/Contrat/Filter.vue';
import CrudContratList from '@/components/Crud/Contrat/List.vue';

@Component({
	components: {
		CrudContratFilter,
		CrudContratList,
		InputSearchEntity,
	}
})
export default class CrudContratSearch extends AbstractSearchVue {
	private filterComponent = CrudContratFilter;
	private listComponent = CrudContratList;
}
