import {Media} from "./Media";
import {Prestation} from "@/models/Prestation";
import {DocumentPrestationService} from "@/xhttp";
import {XHTTP} from "@/shared/xhttp";

@XHTTP(DocumentPrestationService, '/documentprestations')
export class DocumentPrestation {

    private _id: number;
    private _media: Media;
    private _prestation: Prestation;
    private _createdAt: Date;

    /////////////
    // Getters //
    /////////////

    public get id(): number {
        return this._id;
    }

    public get media(): Media {
        return this._media;
    }

    public get prestation(): Prestation {
        return this._prestation;
    }

    public get createdAt(): Date {
        return this._createdAt;
    }

    /////////////
    // Setters //
    /////////////

    public set media(value: Media) {
        this._media = value;
    }

    public set prestation(value: Prestation) {
        this._prestation = value;
    }

    ///////////////
    // Serialize //
    ///////////////

    public fromJSON(data: any): this {
        this._id        = data.id;
        this._media     = (new Media()).fromJSON(data.media);
        this._createdAt = data.createdAt ? new Date(data.createdAt) : null;
        return this;
    }
}
