











	import {Component, Vue} from '@/shared/component';
    import {Prop} from "vue-property-decorator";
	
	@Component()
	export default class InlineMenu extends Vue {
	    @Prop({ type: String, default: ''}) public contentClass: string;
	}
