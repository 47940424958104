import Vue from 'vue';
import Vuex, {ActionContext, Store} from 'vuex';
import {AscDesc, ResultType, XHTTPService} from '@/shared/xhttp';
import {
	PaginatorActionWithFilters,
	PaginatorFilterState,
	PaginatorMutationFilter,
	StandardAction
} from '@/shared/utils';
import {Chantier, Contrat, Prestation, PrestationType} from '@/models';
import {PrestationService} from '@/xhttp';
import store from '@/stores';

Vue.use(Vuex);

export class PrestationFilter {

	public prestationType: PrestationType[] = [];
	public payer	      : boolean[] = [];
	public remarques: string = '';
	public commune: string = '';
	public client: string = '';
	public contratText: string = '';
	public contrat: Contrat[] = [];
	public chantier: Chantier[] = [];
	public hasChantier: boolean[] = [];
	public finish: boolean[] = [];

	public clear(): void {
		this.prestationType = [];
		this.remarques = '';
		this.commune = '';
		this.client = '';
		this.payer = [];
		this.contrat = [];
		this.chantier = [];
		this.hasChantier = [];
		this.finish = [];
	}

	public toString(): string {
		return JSON.stringify({
			prestationType: this.prestationType.map(pt => pt.id),
			payer         : this.payer,
			remarques     : this.remarques,
			commune     : this.commune,
			client     : this.client,
			contratText   : this.contratText,
			contrat       : this.contrat.map(c => c.id),
			chantier      : this.chantier.map(c => c.id),
			hasChantier   : this.hasChantier,
			finish        : this.finish,
		});
	}
}

export class PrestationState extends PaginatorFilterState<PrestationFilter> {
	public prestation: Prestation = null;
	public prestations: ResultType<Prestation> = null;
	public constructor() {
		super(new PrestationFilter(), 'prestations', 'prestation', 'id', AscDesc.DESC, 9999999);
	}
}

class PrestationStore {

	@XHTTPService(() => Prestation)
	private prestationService: PrestationService;
	private  _store: Store<any>;

	public state: PrestationState = new PrestationState();

	public mutations = {
		...PaginatorMutationFilter(() => this._store, 'prestations', 'prestation'),
	};

	public actions = {
		...PaginatorActionWithFilters<Prestation, PrestationState>(() => Prestation, 'prestations', 'prestation'),
		...StandardAction<Prestation, PrestationState>(() => Prestation, 'prestation'),
		
		async putMarkPaid(context: ActionContext<PrestationState, any>, prestation: Prestation) {
			let instancePrestation = await instance.prestationService.putMarkPaid(prestation)
			return instancePrestation;
		},

		putMarkUnpaid(context: ActionContext<PrestationState, any>, prestation: Prestation) {
			return instance.prestationService.putMarkUnpaid(prestation);
		},

		async putMarkPayPrepared(context: ActionContext<PrestationState, any>, prestation: Prestation) {
			let instancePrestation = await instance.prestationService.putMarkPayPrepared(prestation)
			return instancePrestation;
		},

		async putMarkNotPayPrepared(context: ActionContext<PrestationState, any>, prestation: Prestation) {
			let instancePrestation = await instance.prestationService.putMarkNotPayPrepared(prestation)
			return instancePrestation;
		},

	};

	public init(store: Store<any>): this {
		this._store = store;
		return this;
	}

}

const instance = new PrestationStore();
const storePrestation = new Vuex.Store(instance);
instance.init(storePrestation);

const instanceSearch = new PrestationStore();
export const storePrestationSearch = new Vuex.Store(instanceSearch);
instanceSearch.init(storePrestationSearch);

store.registerModule('prestation', storePrestation);
store.registerModule('prestationSearch', storePrestationSearch);

export default storePrestation;
