import {Contact} from '@/models';

export default [
	{
		path: '/contact',
		name: 'contact',
		meta: {
			title: 'Liste des contacts',
			granted: [ 'API_GET_CONTACT' ]
		},
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/Contact.vue')
	},
	{
		path: '/contact/show/:id',
		name: 'contact-show',
		props: {
			slotData: () => ({
				component: import(/* webpackChunkName: "dashboard" */ '../components/Crud/Contact/Show.vue'),
				class: Contact
			}),
			editRoute: { name: 'contact-edit' },
		},
		meta: {
			title: 'Détails d\'un contact',
			granted: [ 'API_GET_CONTACT' ]
		},
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/EditShow.vue')
	},
	{
		path: '/contact/edit/:id',
		name: 'contact-edit',
		props: {
			slotData: () => ({
				component: import(/* webpackChunkName: "dashboard" */ '../components/Crud/Contact/Edit.vue'),
				class: Contact
			}),
		},
		meta: {
			title: 'Edition d\'un contact',
			granted: [ 'API_PUT_CONTACT' ]
		},
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/EditShow.vue')
	},
];