







































import {Component, Vue} from '@/shared/component';
import {Stored} from 'vue-stored-prop-decorator';
import {Token, TokenLogin} from '@/models';

import storeToken from '@/stores/modules/token';
import storeLoader from '@/stores/modules/loader';

import LoginForm from '@/components/Login/LoginForm.vue';
import ResetPasswordRequestForm from '@/components/Login/ResetPasswordRequestForm.vue';

@Component({
	components: {
		LoginForm,
		ResetPasswordRequestForm,
	}
})
export default class ViewLogin extends Vue {
	
	@Stored(() => storeLoader)
	private loading!: boolean;
	
	@Stored(() => storeToken)
	private token!: Token;
	
	private tokenLogin: TokenLogin = new TokenLogin();
	private displayResetPassword = false;
	
	public mounted(): void {
		this.noContainer();
		if (this.token) {
			this.$router.push({ name: 'home' })
		}
	}
}

