import {Tiers, TiersType} from '@/models';

export default [
	{
		path: '/proprietaire',
		name: 'proprietaire',
		meta: {
			title: 'Liste des proprietaires',
			granted: [ 'API_GET_TIERS' ],
			type: TiersType.PROPRIETAIRE
		},
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/Tiers.vue')
	},
	{
		path: '/scierie',
		name: 'scierie',
		meta: {
			title: 'Liste des scieries',
			granted: [ 'API_GET_TIERS' ],
			type: TiersType.SCIERIE
		},
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/Tiers.vue')
	},
	{
		path: '/tiers/show/:id',
		name: 'tiers-show',
		props: {
			slotData: () => ({
				component: import(/* webpackChunkName: "dashboard" */ '../components/Crud/Tiers/Show.vue'),
				class: Tiers
			}),
			editRoute: { name: 'tiers-edit' },
		},
		meta: {
			title: 'Détails d\'un propriétaire ou scierie',
			granted: [ 'API_GET_TIERS' ]
		},
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/EditShow.vue')
	},
	{
		path: '/tiers/edit/:id',
		name: 'tiers-edit',
		props: {
			slotData: () => ({
				component: import(/* webpackChunkName: "dashboard" */ '../components/Crud/Tiers/Edit.vue'),
				class: Tiers
			}),
		},
		meta: {
			title: 'Edition d\'un propriétaire ou scierie',
			granted: [ 'API_PUT_TIERS' ]
		},
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/EditShow.vue')
	},
];
