import {Media} from "./Media";
import {CubageLivraison} from "@/models/CubageLivraison";
import {DocumentCubageLivraisonService} from "@/xhttp";
import {XHTTP} from "@/shared/xhttp";

@XHTTP(DocumentCubageLivraisonService, '/documentcubagelivraisons')
export class DocumentCubageLivraison {

    private _id: number;
    private _media: Media;
    private _cubage: CubageLivraison;
    private _createdAt: Date;

    /////////////
    // Getters //
    /////////////

    public get id(): number {
        return this._id;
    }

    public get media(): Media {
        return this._media;
    }

    public get cubage(): CubageLivraison {
        return this._cubage;
    }

    public get createdAt(): Date {
        return this._createdAt;
    }

    /////////////
    // Setters //
    /////////////

    public set media(value: Media) {
        this._media = value;
    }

    public set cubage(value: CubageLivraison) {
        this._cubage = value;
    }

    ///////////////
    // Serialize //
    ///////////////

    public fromJSON(data: any): this {
        this._id        = data.id;
        this._media     = (new Media()).fromJSON(data.media);
        this._createdAt = data.createdAt ? new Date(data.createdAt) : null;
        return this;
    }
}
