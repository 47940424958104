import {Parcelle} from '@/models';

export default [
	{
		path: '/parcelle',
		name: 'parcelle',
		meta: {
			title: 'Liste des parcelles',
			granted: [ 'API_GET_PARCELLE' ]
		},
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/Parcelle.vue')
	},
	{
		path: '/parcelle/show/:id',
		name: 'parcelle-show',
		props: {
			slotData: () => ({
				component: import(/* webpackChunkName: "dashboard" */ '../components/Crud/Parcelle/Show.vue'),
				class: Parcelle
			}),
			editRoute: { name: 'parcelle-edit' },
		},
		meta: {
			title: 'Détails d\'une parcelle',
			granted: [ 'API_GET_PARCELLE' ]
		},
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/EditShow.vue')
	},
	{
		path: '/parcelle/edit/:id',
		name: 'parcelle-edit',
		props: {
			slotData: () => ({
				component: import(/* webpackChunkName: "dashboard" */ '../components/Crud/Parcelle/Edit.vue'),
				class: Parcelle
			}),
		},
		meta: {
			title: 'Edition d\'une parcelle',
			granted: [ 'API_PUT_PARCELLE' ]
		},
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/EditShow.vue')
	},
];