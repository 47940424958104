
























import {Stored} from 'vue-stored-prop-decorator';
import {Component, Vue} from '@/shared/component';
import {Token, User} from '@/models';

import caller from '@/shared/caller';
import granted from '@/shared/granted';

import storeToken from '@/stores/modules/token';
import storeUser from '@/stores/modules/user';

import NavigationMainMenu from '@/components/Navigation/MainMenu.vue';
import NavigationAppBar from '@/components/Navigation/AppBar.vue';
import Notify from '@/components/Notify.vue';

import { locale, loadMessages } from "devextreme/localization";
import frMessages from 'devextreme/localization/messages/fr.json';

@Component({
	components: {
		NavigationMainMenu,
		NavigationAppBar,
		Notify,
	}
})
export default class App extends Vue {

	@Stored(() => storeToken)
	private token!: Token;

	@Stored(() => storeUser)
	private me!: User;

	public created() {
		loadMessages(frMessages);
		loadMessages({
			"fr": {
				"dxFileUploader-dropFile": "Déposer un fichier",
			}
		});
		locale('fr')
	}


	public async mounted(): Promise<void> {

		this.initCaller();

		try {

			await storeUser.dispatch('getMe');

			if (!granted.checkRouteGranted(this.$route)) {
				this.notify('Accès refusé', 'error');
				if (granted.isConnected()) {
					this.$router.push({ name: 'home'});
					return;
				}
				this.$router.push({ name: 'login'});
				return;
			}
		} catch (e) {
			if (!this.me) {
				this.$router.push({ name: 'login'});
			}
		}
		storeToken.commit('firstPageLoaded');
	}

	private initCaller(): void {
		// Gestion des erreurs Ajax erreur de login
		this.addEventObject(caller, 'error', error => {
			if (error.status === 401) { // Access deny
				if (this.token) {
					this.notify('Vous avez été déconnecté', 'error');
				}
				storeToken.dispatch('clear');
				if (this.$route.name !== 'login') {
					this.$router.push({name: 'login'});
				}
			} else
			if (error.status === 403) { // Access deny
				if (this.token) {
					if (!this.$route.meta.loginPage) {
						this.notify('Accès refusé', 'error');
					}
				}
			} else {
				this.notify('Une erreur est survenue', 'error');
			}
		});
	}

}
