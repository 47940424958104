
























































import {Emit, Prop} from 'vue-property-decorator';
import {Component, Vue} from '@/shared/component';
import {DateFilter, DateFilterMode} from '@/shared/filter';

import InputDatePicker from '@/components/Input/DatePicker.vue';

@Component({
	components: {
		InputDatePicker
	}
})
export default class InputDateFilter extends Vue {
	
	@Prop({type: DateFilter, required: true }) private value : DateFilter;
	@Prop({type: String    , default: 'Date'}) private label: string;
	@Prop ({type: Boolean  , default: false }) private clearable!: boolean;
	@Prop ({type: Boolean  , default: false }) private dense!: boolean;
	
	private oldValue: DateFilter = new DateFilter();
	private DateFilterMode = DateFilterMode;
	
	public mounted(): void {
		this.setOldValue();
	}
	
	private setOldValue(): void {
		if (this.value.equal) { this.value.equal.setHours(0, 0, 0, 0); }
		if (this.value.start) { this.value.start.setHours(0, 0, 0, 0); }
		if (this.value.end  ) { this.value.end  .setHours(0, 0, 0, 0); }
		this.oldValue.mode  = this.value.mode;
		this.oldValue.equal = (this.value.equal ? new Date(this.value.equal) : null);
		this.oldValue.start = (this.value.start ? new Date(this.value.start) : null);
		this.oldValue.end   = (this.value.end   ? new Date(this.value.end  ) : null);
	}
	
	private onChange(): void {
		if (
			this.oldValue.mode !== this.value.mode ||
			(this.oldValue.equal ? this.oldValue.equal.getTime() : null) !== (this.value.equal ? this.value.equal.getTime() : null) ||
			(this.oldValue.start ? this.oldValue.start.getTime() : null) !== (this.value.start ? this.value.start.getTime() : null) ||
			(this.oldValue.end   ? this.oldValue.end  .getTime() : null) !== (this.value.end   ? this.value.end  .getTime() : null)
		) {
			this.setOldValue();
			this.$emit('change', this.value);
		}
	}
}
