import {XHTTP} from '@/shared/xhttp';
import {DateHelper} from "@/shared/utils";


export class FraisChantier {
	
	private _id: number;
	private _montant: number; 
	private _reference: string;

	/////////////
	// Getters //
	/////////////

	public get id(): number {
		return this._id;
	}

	public get montant(): number {
		return this._montant;
	}

	public get reference(): string {
		return this._reference;
	}

	/////////////
	// Setters //
	/////////////

	public set montant(value: number) {
		this._montant = value;
	}

	public set reference(value: string) {
		this._reference = value;
	}

	///////////////
	// Serialize //
	///////////////

	public toJSON(): any {
		return {
			id            : this._id,
			montant       : this._montant,
			reference : this._reference,
		};
	}
	
	public fromJSON(data: any): this {
		this._id           = data.id
		this._montant        = data.montant;
		this._reference  = data.reference;
		return this;
	}
}
