









































import {Emit, Prop} from "vue-property-decorator";
import {InOut} from 'vue-inout-prop-decorator';
import {Component, Vue} from '@/shared/component';

import {Contrat} from '@/models';

import CrudContratEdit from '@/components/Crud/Contrat/Edit.vue';

@Component({
	components: {
		CrudContratEdit,
	}
})
export default class CrudContratEditDialog extends Vue {
	@InOut({ type: Contrat  , isVModel: true }) private value!: Contrat;
	@Prop({ type: Boolean, default: false}) showOnClose: boolean;
	
	private step: number = 1;

	@Emit()
	private onSubmit(contrat: Contrat) {
		// Ouvre à la fermeture
		if (this.showOnClose) {
			this.$router.push({name: 'contrat-show', params: { id : this.value.id.toString() }});
		}
		this.onClose();
	}

	@Emit()
	private onClose() {
		this.value = null;
		this.step = 1;
	}
}
