import Vue from 'vue';
import Vuex from 'vuex';
import {AscDesc, ResultType, XHTTPService} from '@/shared/xhttp';
import {
	PaginatorActionWithFilters,
	PaginatorFilterState,
	PaginatorMutationFilter,
	StandardAction,
} from '@/shared/utils';
import {Contrat, Tiers} from '@/models';
import {ContratService} from '@/xhttp';
import {DateFilter} from '@/shared/filter';

import store from '@/stores';

Vue.use(Vuex);

export class ContratFilter {
	
	public numero: string = '';
	public reference: string = '';
	public remarques: string = '';
	public state: ContratState [] = [];
	public dtCommand: DateFilter = new DateFilter();
	public tiers: Tiers[] = [];
	
	public clear(): void {
		this.numero = '';
		this.reference = '';
		this.remarques = '';
		this.state = [];
		this.dtCommand = new DateFilter();
		this.tiers = [];
	}

	public toString(): string {
		return JSON.stringify({
			numero   : this.numero,
			reference: this.reference,
			remarques: this.remarques,
			state    : this.state,
			dtCommand: this.dtCommand.toJSON(),
			tiers    : this.tiers.map(t => t.id),
		});
	}
}

class ContratState extends PaginatorFilterState<ContratFilter, Contrat> {
	contrat: Contrat = null;
	contrats: ResultType<Contrat> = null;
	public constructor() {
		super(new ContratFilter(), 'contrats', 'contrat', 'id', AscDesc.DESC, 20);
	}
}

class ContratStore {
	
	@XHTTPService(() => Contrat)
	private contratService: ContratService;
	
	public state: ContratState = new ContratState();
	
	public mutations = {
		...PaginatorMutationFilter<Contrat>(() => storeContrat, 'contrats', 'contrat'),
	};
	
	public actions = {
		...PaginatorActionWithFilters<Contrat, ContratState>(() => Contrat, 'contrats', 'contrat'),
		...StandardAction<Contrat, ContratState>(() => Contrat, 'contrat'),
	};
	
}
const instance = new ContratStore();
const storeContrat = new Vuex.Store(instance);
store.registerModule('contrat', storeContrat);
export default storeContrat;
