








































import {Prop, Watch} from "vue-property-decorator";
import {Stored} from "vue-stored-prop-decorator";
import {Component, Vue} from '@/shared/component';
import {Chantier, Contrat, Prestation} from '@/models';

import storePrestation, {PrestationFilter, PrestationState} from '@/stores/modules/prestation';

import Checked from '@/components/Checked.vue';
import CrudList from '@/components/Crud/List.vue';
import TableHead from '@/components/Paginate/TableHead.vue';
import TableCell from '@/components/Paginate/TableCell.vue';
import {Store} from "vuex";
import {PaginatorState} from "@/shared/utils";

@Component({
	components: {
		CrudList,
		TableHead,
		TableCell,
		Checked,
	}
})
export default class CrudPrestationListForSelect extends Vue {

	@Prop({type: Boolean, default: true  }) public paginate!: boolean;
	@Prop({type: Boolean, default: false }) public dense!   : boolean;

	@Prop({type: [ Boolean, Contrat ] , default: false }) public contrat! : Contrat|boolean;
	@Prop({type: [ Boolean, Chantier ], default: false }) public chantier!: Chantier|boolean;
	@Prop({type: Store                , default: null  }) public store!   : Store<PrestationState>;

	private get filters(): PrestationFilter {
		return this.storePrestation.state.filters;
	}

	private get show(): boolean {
		return (this.contrat === false || !!this.contrat) && (this.chantier === false || !!this.chantier);
	}

	private get storePrestation(): Store<PrestationState> {
		return this.store ? this.store : storePrestation;
	}

	private mounted(): void {
		this.contratWatcher();

		this.filters.hasChantier = [ false ];
	}
	
	@Watch('contrat')
	@Watch('chantier')
	private contratWatcher(): void {
		if (this.contrat) {
			this.filters.contrat = [ <Contrat>this.contrat ];
		}
		if (this.chantier) {
			this.filters.chantier = [ <Chantier>this.chantier ];
		}
	}

	private bodyClass(item: Prestation): string {
		return item.totalPriceSale - item.totalPriceBuy >= 0 ? 'prestation-sale' : 'prestation-buy';
	}
}
