import {CubageLivraison} from '@/models';

export default [
	{
		path: '/cubagelivraison',
		name: 'cubagelivraison',
		meta: {
			title: 'Liste des livraisons',
			granted: [ 'API_GET_CUBAGELIVRAISON' ]
		},
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/CubageLivraison.vue')
	},
	{
		path: '/suivilivraison',
		name: 'suivilivraison',
		meta: {
			title: 'Suivi des livraisons',
			granted: [ 'API_GET_CUBAGELIVRAISON' ]
		},
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/SuiviLivraison.vue')
	},
	{
		path: '/cubagelivraison/show/:id',
		name: 'cubagelivraison-show',
		props: {
			slotData: () => ({
				component: import(/* webpackChunkName: "dashboard" */ '../components/Crud/CubageLivraison/Edit.vue'),
				class: CubageLivraison
			}),
		},
		meta: {
			title: 'Détails d\'une livraison',
			granted: [ 'API_PUT_CUBAGELIVRAISON' ],
			editCallback: (livraison: CubageLivraison) => livraison && !livraison.purchase
		},
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/EditShow.vue')
	},
	{
		path: '/cubagelivraison/edit/:id',
		name: 'cubagelivraison-edit',
		props: {
			slotData: () => ({
				component: import(/* webpackChunkName: "dashboard" */ '../components/Crud/CubageLivraison/Edit.vue'),
				class: CubageLivraison
			}),
		},
		meta: {
			title: 'Edition d\'une livraison',
			granted: [ 'API_PUT_CUBAGELIVRAISON' ]
		},
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/EditShow.vue')
	},
];
