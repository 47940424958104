import Vue from 'vue';
import Vuex from 'vuex';
import {AscDesc, ResultType, XHTTPService} from '@/shared/xhttp';
import {NumberFilter} from '@/shared/filter';
import {
	PaginatorActionWithFilters,
	PaginatorFilterState,
	PaginatorMutationFilter,
	StandardAction
} from '@/shared/utils';
import {Chauffeur, Tiers} from '@/models';
import {ChauffeurService} from '@/xhttp';
import store from '@/stores';

Vue.use(Vuex);

export class ChauffeurFilter {
	
	firstName: string = '';
	lastName: string = '';
	
	public clear(): void {
		this.firstName = '';
		this.lastName = '';
	}

	public toString(): string {
		return JSON.stringify({
			firstName: this.firstName,
			lastName : this.lastName,
		});
	}
}

class ChauffeurState extends PaginatorFilterState<ChauffeurFilter> {
	public chauffeur: Chauffeur = null;
	public chauffeurs: ResultType<Chauffeur> = null;
	public constructor() {
		super(new ChauffeurFilter(), 'chauffeurs', 'chauffeur', 'id', AscDesc.DESC, 50);
	}
}

class ChauffeurStore {
	
	@XHTTPService(() => Chauffeur)
	private chauffeurService: ChauffeurService;
	
	public state: ChauffeurState = new ChauffeurState();
	
	public mutations = {
		...PaginatorMutationFilter(() => storeChauffeur, 'chauffeurs', 'chauffeur'),
	};
	
	public actions = {
		...PaginatorActionWithFilters<Chauffeur, ChauffeurState>(() => Chauffeur, 'chauffeurs', 'chauffeur'),
		...StandardAction<Chauffeur, ChauffeurState>(() => Chauffeur, 'chauffeur'),
	};
	
}
const instance = new ChauffeurStore();
const storeChauffeur = new Vuex.Store(instance);
store.registerModule('chauffeur', storeChauffeur);
export default storeChauffeur;
