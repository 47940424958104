
















import {Component} from '@/shared/component';
import {AbstractSearchVue} from '@/components/Abstract/AbstractSearchVue';

import InputSearchEntity from '@/components/Input/SearchEntity.vue';
import CrudChauffeurFilter from '@/components/Crud/Chauffeur/Filter.vue';
import CrudChauffeurListForSelect from '@/components/Crud/Chauffeur/ListForSelect.vue';

@Component({
	components: {
		CrudChauffeurFilter,
		CrudChauffeurListForSelect,
		InputSearchEntity,
	}
})
export default class CrudChauffeurSearch extends AbstractSearchVue {
	private filterComponent = CrudChauffeurFilter;
	private listComponent = CrudChauffeurListForSelect;
}
