import {XHTTP} from '@/shared/xhttp';
import {CubagePlateformeRestantService} from '@/xhttp';
import {DateHelper} from "@/shared/utils";
import {BoisType} from './BoisType';
import {BoisQuality} from './BoisQuality';
import {BoisSize} from './BoisSize';
import {Plateforme} from './Plateforme';
import {PlateformeCase} from './PlateformeCase';

@XHTTP(CubagePlateformeRestantService, '/cubage-plateforme-restants')
export class CubagePlateformeRestant {

	private _id: number;
	private _dateDelivery: Date = new Date();
	private _plateforme: Plateforme;
	private _case: PlateformeCase;
	private _boisType: BoisType;
	private _boisQuality: BoisQuality;
	private _boisSize: BoisSize;
	private _volume: number;
	private _coef: number;

	/////////////
	// Getters //
	/////////////

	public get id(): number {
		return this._id;
	}

	public get dateDelivery(): Date {
		return this._dateDelivery;
	}

	public get plateforme(): Plateforme {
		return this._plateforme;
	}

	public get case(): PlateformeCase {
		return this._case;
	}

	public get boisType(): BoisType {
		return this._boisType;
	}

	public get boisQuality(): BoisQuality {
		return this._boisQuality;
	}

	public get boisSize(): BoisSize {
		return this._boisSize;
	}

	public get volume(): number {
		return this._volume;
	}

	public get coef(): number {
		return this._coef;
	}

	public get all(): string {
		return this.boisType.name + '-' + this.boisQuality.name + '-' + this.boisSize.name + '-' + (this.case ? this.case.name : 'Sans case')
	}

	/////////////
	// Setters //
	/////////////

	public set dateDelivery(value: Date) {
		this._dateDelivery = value;
	}

	public set plateforme(value: Plateforme) {
		this._plateforme = value;
	}

	public set case(value: PlateformeCase) {
		this._case = value;
	}

	public set boisType(value: BoisType) {
		this._boisType = value;
	}

	public set boisQuality(value: BoisQuality) {
		this._boisQuality = value;
	}

	public set boisSize(value: BoisSize) {
		this._boisSize = value;
	}

	public set volume(value: number) {
		this._volume = value;
	}

	public set coef(value: number) {
		this._coef = value;
	}

	///////////////
	// Serialize //
	///////////////

	public toJSON(): any {
		return {
			id            : this._id,
			dateDelivery    : this._dateDelivery ? DateHelper.toLocalizedString(this._dateDelivery) : null,
			plateforme    : this._plateforme ? this._plateforme.id : null,
			case    : this._case ? this._case.id : null,
			boisType    : this._boisType ? this._boisType.id : null,
			boisQuality : this._boisQuality ? this._boisQuality.id : null,
			boisSize    : this._boisSize ? this._boisSize.id : null,
			volume : this._volume,
			coef        : this._coef,
		};
	}

	public fromJSON(data: any): this {
		this._id           = data.id
		this._dateDelivery         = data.dateDelivery ? DateHelper.fromLocalizedString(data.dateDelivery) : null;
		this._plateforme     = data.plateforme ? (new Plateforme()).fromJSON(data.plateforme) : null;
		this._case     = data.case ? (new PlateformeCase()).fromJSON(data.case) : null;
		this._boisType     = data.boisType ? (new BoisType()).fromJSON(data.boisType) : null;
		this._boisQuality  = data.boisQuality ? (new BoisQuality()).fromJSON(data.boisQuality) : null;
		this._boisSize     = data.boisSize ? (new BoisSize()).fromJSON(data.boisSize) : null;
		this._volume  = data.volume;
		this._coef         = data.coef;
		return this;
	}
}
