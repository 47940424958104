import {BoisSize} from './BoisSize';
import {BoisQuality} from './BoisQuality';
import {BoisType} from './BoisType';

export class PurchasePrice {

	private _id: number;
	private _price: number = 0;
	private _tva: number = 0;
	private _boisType: BoisType = null;
	private _boisQuality: BoisQuality = null;
	private _boisSize: BoisSize = null;
	private _volume: number = null;
	private _createdAt: Date;

	/////////////
	// Getters //
	/////////////

	public get id(): number {
		return this._id;
	}

	public get price(): number {
		return this._price;
	}

	public get tva(): number {
		return this._tva;
	}

	public get boisType(): BoisType {
		return this._boisType;
	}

	public get boisQuality(): BoisQuality {
		return this._boisQuality;
	}

	public get boisSize(): BoisSize {
		return this._boisSize;
	}

	public get volume(): number {
		return this._volume;
	}

	public get unitPrice(): number {
		if (!this.volume) {
			return null;
		}
		return Math.round(this.price / this.volume * 100) / 100;
	}
	
	public get priceTTC(): number {
		return Math.round((this.price + this.price * this.tva) * 100) / 100;
	}

	public get createdAt(): Date {
		return this._createdAt;
	}
	
	public get displayBois(): string { 
		const list: string[] = [];
		if (this.boisType) {
			list.push(this.boisType.name);
		}
		if (this.boisQuality) {
			list.push(this.boisQuality.name);
		}
		if (this.boisSize) {
			list.push(this.boisSize.name);
		}
		const text = list.join(', ');
		return text ? '('+text+')': '';
	}

	/////////////
	// Setters //
	/////////////

	public set price(value: number) {
		this._price = value;
	}

	public set tva(value: number) {
		this._tva = value;
	}

	public set boisType(value: BoisType) {
		this._boisType = value;
	}

	public set boisQuality(value: BoisQuality) {
		this._boisQuality = value;
	}

	public set boisSize(value: BoisSize) {
		this._boisSize = value;
	}

	public set volume(value: number) {
		this._volume = value;
	}

	public set unitPrice(value: number) {
		if (this.volume) {
			this.price = Math.round(value * this.volume * 100) / 100;
		}
	}

	public set priceTTC(value: number) {
		this.price = Math.round(value / (1 + this.tva) * 100) / 100;
	}

	///////////////
	// Serialize //
	///////////////

	public toJSON(): any {
		return {
			id         : this._id,
			price      : this._price,
			tva        : this._tva,
			boisType   : this._boisType    ? this._boisType.id    : null,
			boisQuality: this._boisQuality ? this._boisQuality.id : null,
			boisSize   : this._boisSize    ? this._boisSize.id    : null,
		};
	}

	public fromJSON(data: any): this {
		this._id          = data.id;
		this._price       = data.price;
		this._tva         = data.tva;
		this._boisType    = data.boisType    ? (new BoisType   ).fromJSON(data.boisType)    : null;
		this._boisQuality = data.boisQuality ? (new BoisQuality).fromJSON(data.boisQuality) : null;
		this._boisSize    = data.boisSize    ? (new BoisSize   ).fromJSON(data.boisSize)    : null;
		this._volume      = data.volume;
		this._createdAt   = data.createdAt ? new Date(data.createdAt) : null;
		return this;
	}
}
