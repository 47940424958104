import Vue from 'vue';
import VueRouter from 'vue-router';
import granted from '@/shared/granted';
import vuetify from '@/shared/vuetify';

import storeNotifier, {NotifyInterface} from '@/stores/modules/notifier';

import Login from '@/views/Login.vue';
import ResetPasswordConfirm from '@/views/ResetPasswordConfirm.vue';

import chantier from './chantier';
import chauffeur from './chauffeur';
import contact from './contact';
import contrat from './contrat';
import parcelle from './parcelle';
import prestation from './prestation';
import user from './user';
import tiers from './tiers';
import token from './token';
import prestationType from './prestationType';
import boisType from './boisType';
import boisQuality from './boisQuality';
import boisSize from './boisSize';
import marquage from './marquage';
import cubageLivraison from './cubageLivraison';
import purchase from './purchase';
import abatteur from './abatteur';
import debardeur from './debardeur';
import plateforme from './plateforme';

Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		name: 'login',
		meta: { loginPage: true },
		component: Login,
	},
	{
		path: '/reset-password-confirm/:email',
		name: 'reset_password_confirm',
		meta: { loginPage: true },
		component: ResetPasswordConfirm,
	},
	{
		path: '/home',
		name: 'home',
		meta: { granted: [] },
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/Home.vue')
	},
	//{
		//path: '/home',
		//name: 'home',
		//meta: { granted: ['NAV_CONTRATS'] },
		//component: () => import(/* webpackChunkName: "dashboard" */ '../views/Chantier.vue')
	//},
	...abatteur,
	...debardeur,
	...chantier,
	...chauffeur,
	...contact,
	...contrat,
	...parcelle,
	...prestation,
	...user,
	...tiers,
	...token,
	...prestationType,
	...boisType,
	...boisQuality,
	...boisSize,
	...marquage,
	...cubageLivraison,
	...purchase,
	...plateforme,
	{
		path: '*',
		redirect: { name: 'home' },
	},
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior (to, from, savedPosition) {
		return { x: 0, y: 0 }
	}
});

router.beforeEach((to: any, from: any, next: Function) => {
	if (granted.firstPageLoaded() && !granted.checkRouteGranted(to)) {
		storeNotifier.dispatch('notify', {
			type: 'error',
			message: 'Accès refusé'
		} as NotifyInterface);
		if (granted.isConnected()) {
			next({ name: 'home'});
			return;
		}
		next({ name: 'login'});
		return;
	}
	next();
});
export default router
