import Vue from 'vue';
import Vuex from 'vuex';
import {ResultType, XHTTPService} from '@/shared/xhttp';
import {
	PaginatorActionWithFilters,
	PaginatorFilterState,
	PaginatorMutation, SetterMutation,
	StandardAction
} from '@/shared/utils';
import {PrestationType} from '@/models';
import {PrestationTypeService} from '@/xhttp';
import store from '@/stores';

Vue.use(Vuex);

export class PrestationTypeFilter {
	
	public name: string = '';
	public sale: boolean[] = [];
	
	public toString(): string {
		return JSON.stringify(this);
	}
}

class PrestationTypeState extends PaginatorFilterState<PrestationTypeFilter> {
	public prestationType: PrestationType = null;
	public prestationTypes: ResultType<PrestationType> = null;
	public prestationTypesAll: ResultType<PrestationType> = null;
	public constructor() {
		super(new PrestationTypeFilter(), 'prestationTypes', 'prestationType');
	}
}

class PrestationTypeStore {
	
	@XHTTPService(() => PrestationType)
	private prestationTypeService: PrestationTypeService;
	
	public state: PrestationTypeState = new PrestationTypeState();
	
	public mutations = {
		...PaginatorMutation(() => storePrestationType, 'prestationTypes', 'prestationType'),
		...SetterMutation([ 'prestationTypesAll' ])
	};
	
	public actions = {
		...StandardAction<PrestationType, PrestationTypeState>(() => PrestationType, 'prestationType'),
		...PaginatorActionWithFilters<PrestationType, PrestationTypeState>(() => PrestationType, 'prestationTypes', 'prestationType'),
	};
	
}
const instance = new PrestationTypeStore();
const storePrestationType = new Vuex.Store(instance);
store.registerModule('prestationType', storePrestationType);
export default storePrestationType;
