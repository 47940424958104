





















	import {Component} from '@/shared/component';
    import {Prop} from "vue-property-decorator";
	import {AbstractSearchVue} from '@/components/Abstract/AbstractSearchVue';

	import {storePrestationSearch} from "@/stores/modules/prestation";
	
	import InputSearchEntity from '@/components/Input/SearchEntity.vue';
	import CrudPrestationFilter from '@/components/Crud/Prestation/Filter.vue';
	import CrudPrestationListForSelect from '@/components/Crud/Prestation/ListForSelect.vue';
	
	@Component({
		components: {
			CrudPrestationFilter,
			CrudPrestationListForSelect,
			InputSearchEntity,
		}
	})
	export default class CrudPrestationSearch extends AbstractSearchVue {

		@Prop({ type: Boolean, default: false }) public contratText!: boolean;
		@Prop({ type: Array, default: () => [] }) public hasChantier!: boolean[];
		
		private filterComponent = CrudPrestationFilter;
		private listComponent = CrudPrestationListForSelect;
		
		private storePrestationSearch = storePrestationSearch;
	}
