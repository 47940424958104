import {XHTTP} from '@/shared/xhttp';
import {PurchaseService} from '@/xhttp';
import {DateHelper} from '@/shared/utils';
import {Tiers} from './Tiers';
import {PurchasePrice} from './PurchasePrice';

@XHTTP(PurchaseService, '/purchases')
export class Purchase {

	private _id: number;
	private _date: Date;
	private _numero: string;
	private _reference: string;
	private _end: boolean = false;
	private _refund: boolean = false;
	private _tiers: Tiers;
	private _purchasePrices: PurchasePrice[] = [];
	private _createdAt: Date;

	/////////////
	// Getters //
	/////////////

	public get id(): number {
		return this._id;
	}

	public get date(): Date {
		return this._date;
	}

	public get numero(): string {
		return this._numero;
	}

	public get reference(): string {
		return this._reference;
	}

	public get end(): boolean {
		return this._end;
	}

	public get refund(): boolean {
		return this._refund;
	}

	public get tiers(): Tiers {
		return this._tiers;
	}

	public get purchasePrices(): PurchasePrice[] {
		return this._purchasePrices;
	}
	
	public get createdAt(): Date {
		return this._createdAt;
	}

	public get totalHT(): number {
		return this._purchasePrices ? this._purchasePrices.map(pp => pp.price).reduce((a, b) => a + b, 0) : null;
	}

	public get totalTTC(): number {
		return this._purchasePrices ? this._purchasePrices.map(pp => pp.price + pp.price * pp.tva).reduce((a, b) => a + b, 0) : null;
	}

	/////////////
	// Setters //
	/////////////

	public set date(value: Date) {
		this._date = value;
	}

	public set numero(value: string) {
		this._numero = value;
	}

	public set reference(value: string) {
		this._reference = value;
	}

	public set end(value: boolean) {
		this._end = value;
	}

	public set refund(value: boolean) {
		this._refund = value;
	}

	public set tiers(value: Tiers) {
		this._tiers = value;
	}

	public set createdAt(value: Date) {
		this._createdAt = value;
	}

	public set purchasePrices(value: PurchasePrice[]) {
		this._purchasePrices = value;
	}

	///////////////
	// Serialize //
	///////////////

	public toJSON(): any {
		return {
			id            : this._id,
			date          : this._date ? DateHelper.toLocalizedString(this._date) : null,
			numero   	  : this._numero,
			reference     : this._reference,
			end           : this._end,
			refund        : this._refund,
			tiers         : this._tiers ? this._tiers.id : null,
			purchasePrices: this._purchasePrices ? this._purchasePrices.map(pp => pp.toJSON()) : null,
			createdAt     : this._createdAt ? DateHelper.toLocalizedString(this._createdAt) : null,
		};
	}

	public fromJSON(data: any): this {
		this._id             = data.id;
		this._date           = data.date ? new Date(data.date) : null;
		this._numero         = data.numero;
		this._reference      = data.reference;
		this._end            = data.end;
		this._refund         = data.refund;
		this._tiers          = data.tiers ? (new Tiers).fromJSON(data.tiers) : null;
		this._purchasePrices = data.purchasePrices ? data.purchasePrices.map(p => (new PurchasePrice).fromJSON(p)) : [];
		this._createdAt      = data.createdAt ? new Date(data.createdAt) : null;
		return this;
	}
}
