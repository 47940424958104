import {XHTTP} from '@/shared/xhttp';
import {CubagePrestationEstimedService} from '@/xhttp';
import {Prestation} from './Prestation';
import {BoisType} from "@/models/BoisType";
import {BoisQuality} from "@/models/BoisQuality";
import {BoisSize} from "@/models/BoisSize";
import {Marquage} from "@/models/Marquage";
import {DocumentCubagePourClient} from "./DocumentCubagePourClient";

@XHTTP(CubagePrestationEstimedService, '/cubage-prestation-estimeds')
export class CubagePrestationEstimed {
	
	private _id: number;
	private _prestation: Prestation;
	private _numero: string;
	private _boisType: BoisType;
	private _boisQuality: BoisQuality;
	private _boisSize: BoisSize;
	private _marquage: Marquage;
	private _volumeInner: number;
	private _volumeOutter: number;
	private _coef: number;
	private _volumeStere: number;
	private _coefStere: number;
	private _documents: DocumentCubagePourClient[] = [];

	/////////////
	// Getters //
	/////////////

	public get id(): number {
		return this._id;
	}

	public get prestation(): Prestation {
		return this._prestation;
	}

	public get numero(): string {
		return this._numero;
	}

	public get boisType(): BoisType {
		return this._boisType;
	}

	public get boisQuality(): BoisQuality {
		return this._boisQuality;
	}

	public get boisSize(): BoisSize {
		return this._boisSize;
	}

	public get marquage(): Marquage {
		return this._marquage;
	}

	public get volumeInner(): number {
		return this._volumeInner;
	}

	public get volumeOutter(): number {
		return this._volumeOutter;
	}

	public get coef(): number {
		return this._coef;
	}

	public get volumeStere(): number {
		return this._volumeStere;
	}

	public get coefStere(): number {
		return this._coefStere;
	}

	public get documents(): DocumentCubagePourClient[] {
		return this._documents;
	}

	/////////////
	// Setters //
	/////////////

	public set id(value : number) {
		this._id = value;
	}

	public set prestation(value: Prestation) {
		this._prestation = value;
	}

	public set numero(value: string) {
		this._numero = value;
	}

	public set boisType(value: BoisType) {
		this._boisType = value;
	}

	public set boisQuality(value: BoisQuality) {
		this._boisQuality = value;
	}

	public set boisSize(value: BoisSize) {
		this._boisSize = value;
	}

	public set marquage(value: Marquage) {
		this._marquage = value;
	}

	public set volumeInner(value: number) {
		this._volumeInner = value;
	}

	public set volumeOutter(value: number) {
		this._volumeOutter = value;
	}

	public set coef(value: number) {
		this._coef = value;
	}

	public set volumeStere(value: number) {
		this._volumeStere = value;
	}

	public set coefStere(value: number) {
		this._coefStere = value;
	}

	/////////
	// Add //
	/////////

	public addDocuments(document: DocumentCubagePourClient): this {
		document.cubage = this;
		if (this._documents.indexOf(document) === -1) {
			this._documents.push(document);
		}
		return this;
	}

	public removeDocuments(document: DocumentCubagePourClient): this {
		document.cubage = this;
		const index = this._documents.indexOf(document);
		if (index !== -1) {
			this._documents.splice(index, 1);
		}
		return this;
	}

	///////////////
	// Serialize //
	///////////////

	public toJSON(): any {
		return {
			id            : this._id,
			prestation  : this._prestation ? this._prestation.id : null,
			numero      : this._numero,
			boisType    : this._boisType ? this._boisType.id : null,
			boisQuality : this._boisQuality ? this._boisQuality.id : null,
			boisSize    : this._boisSize ? this._boisSize.id : null,
			marquage    : this._marquage ? this._marquage : null,
			volumeInner : this._volumeInner,
			volumeOutter: this._volumeOutter,
			coef        : this._coef,
			volumeStere: this._volumeStere,
			coefStere        : this._coefStere,
		};
	}
	
	public fromJSON(data: any): this {
		this._id           = data.id
		this._prestation   = data.prestation ? (new Prestation()).fromJSON(data.prestation) : null;
		this._numero       = data.numero;
		this._boisType     = data.boisType ? (new BoisType()).fromJSON(data.boisType) : null;
		this._boisQuality  = data.boisQuality ? (new BoisQuality()).fromJSON(data.boisQuality) : null;
		this._boisSize     = data.boisSize ? (new BoisSize()).fromJSON(data.boisSize) : null;
		this._marquage     = data.marquage ? (new Marquage()).fromJSON(data.marquage) : null;
		this._volumeInner  = data.volumeInner;
		this._volumeOutter = data.volumeOutter;
		this._coef         = data.coef;
		this._volumeStere = data.volumeStere;
		this._coefStere         = data.coefStere;
		if (data.documentCubagePourClients)  {
			for (const d of data.documentCubagePourClients) {
				this.addDocuments((new DocumentCubagePourClient()).fromJSON(d));
			}
		}
		return this;
	}
}
