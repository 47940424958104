







































































































































	import {InOut} from 'vue-inout-prop-decorator';
	import {Component} from '@/shared/component';
	import {AbstractEditVue} from '@/components/Abstract/AbstractEditVue';
  import {Contrat, ContratState, Tiers, TiersType} from '@/models';

	import storeContrat from '@/stores/modules/contrat';

	import InputDatePicker from '@/components/Input/DatePicker.vue';
  import CrudTiersSearch from '@/components/Crud/Tiers/Search.vue';
  import CrudUserSearch from '@/components/Crud/User/Search.vue';
  import CrudTiersEditDialog from '@/components/Crud/Tiers/EditDialog.vue';

	@Component({
		components: {
			InputDatePicker,
      CrudTiersSearch,
      CrudUserSearch,
      CrudTiersEditDialog,
		}
	})
	export default class CrudContratEdit extends AbstractEditVue<Contrat> {

		@InOut({ type: Number, default: 1 }) private step: number;

		private editProprietaire: Tiers = null;
    
		private TiersType = TiersType;
		private ContratState = ContratState;

		public constructor() {
			super(
				storeContrat,
				'Contrat modifié',
				'Contrat créé',
			);
		}
		
		private onCreateTiers(): void {
			this.editProprietaire = new Tiers();
			this.editProprietaire.type = TiersType.PROPRIETAIRE;
		}
    
		private onCreatedTiers(tiers: Tiers): void {
			this.item.tiers = tiers;
			this.step = 2;
			this.$forceUpdate();
		}
	}
