import {AscDesc, GenericService, ResultType} from '@/shared/xhttp';
import {CubageChantierRestant, Chantier, BoisType, BoisQuality, BoisSize} from '@/models';
import caller from '@/shared/caller'; 

export class CubageChantierRestantService extends GenericService<CubageChantierRestant> {

	public getCTotalChantier(chantier: Chantier, limit: number = 20, page: number = 0, order: string = null, direction: AscDesc = null, query: any = {}): Promise<ResultType<CubageChantierRestant>> {
		return super.getC(`/total-chantier/${chantier.id}`, limit, page, order, direction, query);
	}

	public getByBois(boisType: BoisType, boisQuality: BoisQuality, boisSize: BoisSize): Promise<ResultType<CubageChantierRestant>> {
		var boisTypeID: number = 0;
		if (boisType) boisTypeID=boisType.id;

		var boisQualityID: number = 0;
		if (boisQuality) boisQualityID=boisQuality.id;

		var boisSizeID: number = 0;
		if (boisSize) boisSizeID=boisSize.id;

		return super.getC(`/chantier-by-bois/${boisTypeID}/${boisQualityID}/${boisSizeID}`, 10000);
	}

}
