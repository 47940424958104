import {XHTTP} from '@/shared/xhttp';
import {ChantierService} from '@/xhttp';
import {DateHelper} from '@/shared/utils';
import {Contact} from './Contact';
import {Prestation} from '@/models/Prestation';
import {DocumentChantier} from "./DocumentChantier";
import {Debardeur} from '@/models/Debardeur';
import {Abatteur} from '@/models/Abatteur';
import {BoisType} from '@/models/BoisType';
import {BoisQuality} from '@/models/BoisQuality';
import {BoisSize} from '@/models/BoisSize';
import {Marquage} from '@/models/Marquage';
import {FraisChantier} from '@/models/FraisChantier';
import {Chauffeur} from '@/models/Chauffeur';
import {CubageChantier} from './CubageChantier';
import {CubagePlateforme} from "@/models/CubagePlateforme";

@XHTTP(ChantierService, '/chantiers')
export class Chantier {

	private _id: number;
	private _name: string = 'CH-?????';
	private _commune: string;
	private _lieudit: string;
	private _GPS: string;
	private _chauffeurParDefaut: Chauffeur;
	private _messagePourChauffeur: string;
	private _messageOperateur: string;
	private _client: string;
	private _payer: boolean = false;
	private _paiementPreparer: boolean = false;
	private _consigne: string;
	private _dtStart: Date;
	private _dtEnd: Date;
	private _dtEndCoupe: Date;
	private _expectedDtStart: Date;
	private _expectedDtEnd: Date;
	private _lien1: string; // localisation 1
	private _lien12: string; // localisation 2
	private _lien13: string; // localisation 3
	private _lien2: string; // accès chantier
	private _lienVersDrive: string;
	private _donneurOrdre: string;
	private _adresseDonneurOrdre: string;
	private _cpDonneurOrdre: string;
	private _villeDonneurOrdre: string;
	private _telephoneDonneurOrdre: string;
	private _emailDonneurOrdre: string;
	private _societeAbattage: string;
	private _contactAbattage: string;
	private _adresseAbattage: string;
	private _cpAbattage: string;
	private _villeAbattage: string;
	private _telephoneAbattage: string;
	private _periodeAbattage: string;
	private _societeDebardage: string;
	private _contactDebardage: string;
	private _adresseDebardage: string;
	private _cpDebardage: string;
	private _villeDebardage: string;
	private _telephoneDebardage: string;
	private _periodeDebardage: string;
	private _consignesSecuriteIntervenants: string;
	private _latitude: number;
	private _longitude: number;
	private _surface: number;
	private _volume200: number;
	private _finDeCoupe: boolean = false;
	private _boisFini: boolean = false; // bois = grumes uniquement
	private _billonsFini: boolean = false;
	private _abatteur: Abatteur = null;
	private _avecGrumes: boolean = false;
	private _grumesDebarde: boolean = false;
	private _dtEndGrumes: Date;
	private _debardeur: Debardeur = null;
	private _avecBillons: boolean = false;
	private _billonsDebarde: boolean = false;
	private _dtEndBillons: Date;
	private _debardeur2: Debardeur = null;
	private _infospe1: boolean = false;
	private _infospe2: boolean = false;
	private _infospe3: boolean = false;
	private _infospe4: boolean = false;
	private _infospe5: boolean = false;
	private _infospe6: boolean = false;
	private _infospe7: boolean = false;
	private _infospe8: boolean = false;
	private _infospe9: boolean = false;
	private _infospe10: boolean = false;
	private _infospe11: boolean = false;
	private _infospe12: boolean = false;
	private _infospe13: boolean = false;
	private _infospe14: boolean = false;
	private _infospe15: boolean = false;
	private _infospe16: boolean = false;
	private _infospe17: boolean = false;
	private _infospe18: boolean = false;
	private _infospe19: boolean = false;
	private _infospe20: boolean = false;
	private _infospe21: boolean = false;
	private _infospe22: boolean = false;
	private _infospe23: boolean = false;
	private _infospe24: boolean = false;
	private _infospe25: boolean = false;
	private _infospe26: boolean = false;
	private _infospe27: boolean = false;
	private _infospe28: boolean = false;
	private _valueinfospe28: number;
	private _infospe29: boolean = false;
	private _infospe30: boolean = false;
	private _infospe31: boolean = false;
	private _infospe32: boolean = false;
	private _infospe33: boolean = false;
	private _intervenants: string;
	private _couverturetelbonne: boolean = false;
	private _zonecouverte: string;
	private _ficheobservations: string;
	private _documents: DocumentChantier[] = [];
	private _photos: DocumentChantier[] = [];
	private _documentChauffeurs: DocumentChantier[] = [];
	private _boisTypes: BoisType[];
	private _boisQualities: BoisQuality[];
	private _boisSizes: BoisSize[];
	private _marquages: Marquage[];
	private _prestations: Prestation[] = [];
	private _cubageChantiers: CubageChantier[] = [];
	private _coutM3Defaut: number;
	private _totalAchatsBois: number;
	private _totalCoutsExploitation: number;
	private _totalVentes: number;
	private _benefices: number;
	private _financialClose: boolean = false;
	private _fraisChantiers: FraisChantier[] = [];
	private _cubagePlateforme: CubagePlateforme;
	// Uniquement pour la creation rapide d'un chantier + contrat + presta
	private _idTiers: number = 0;
	private _idPrestationType: number = 0;
	private _TypeAchat: string = '';

	/////////////
	// Getters //
	/////////////

	public get id(): number {
		return this._id;
	}

	public get fraisChantiers(): FraisChantier[] {
		return this._fraisChantiers;
	}

	public get totalAchatsBois(): number {
		return this._totalAchatsBois;
	}

	public get totalCoutsExploitation(): number {
		return this._totalCoutsExploitation;
	}

	public get totalVentes(): number {
		return this._totalVentes;
	}

	public get benefices(): number {
		return this._benefices;
	}

	public get financialClose(): boolean {
		return this._financialClose;
	}

	public get name(): string {
		return this._name;
	}

	public get commune(): string {
		return this._commune;
	}

	public get lieudit(): string {
		return this._lieudit;
	}

	public get GPS(): string {
		return this._GPS;
	}

	public get chauffeurParDefaut(): Chauffeur {
		return this._chauffeurParDefaut;
	}

	public get messagePourChauffeur(): string {
		return this._messagePourChauffeur;
	}

	public get messageOperateur(): string {
		return this._messageOperateur;
	}

	public get client(): string {
		return this._client;
	}

	public get payer(): boolean {
		return this._payer;
	}

	public get paiementPreparer(): boolean {
		return this._paiementPreparer;
	}

	public get consigne(): string {
		return this._consigne;
	}

	public get dtStart(): Date {
		return this._dtStart;
	}

	public get dtEnd(): Date {
		return this._dtEnd;
	}

	public get dtEndCoupe(): Date {
		return this._dtEndCoupe;
	}

	public get expectedDtStart(): Date {
		return this._expectedDtStart;
	}

	public get expectedDtEnd(): Date {
		return this._expectedDtEnd;
	}

	public get lien1(): string {
		return this._lien1;
	}

	public get lien12(): string {
		return this._lien12;
	}

	public get lien13(): string {
		return this._lien13;
	}

	public get lien2(): string {
		return this._lien2;
	}

	public get lienVersDrive(): string {
		return this._lienVersDrive;
	}

	public get donneurOrdre(): string {
		return this._donneurOrdre;
	}

	public get adresseDonneurOrdre(): string {
		return this._adresseDonneurOrdre;
	}

	public get cpDonneurOrdre(): string {
		return this._cpDonneurOrdre;
	}

	public get villeDonneurOrdre(): string {
		return this._villeDonneurOrdre;
	}

	public get telephoneDonneurOrdre(): string {
		return this._telephoneDonneurOrdre;
	}

	public get emailDonneurOrdre(): string {
		return this._emailDonneurOrdre;
	}

	public get societeAbattage(): string {
		return this._societeAbattage;
	}

	public get contactAbattage(): string {
		return this._contactAbattage;
	}

	public get adresseAbattage(): string {
		return this._adresseAbattage;
	}

	public get cpAbattage(): string {
		return this._cpAbattage;
	}

	public get villeAbattage(): string {
		return this._villeAbattage;
	}

	public get telephoneAbattage(): string {
		return this._telephoneAbattage;
	}

	public get periodeAbattage(): string {
		return this._periodeAbattage;
	}

	public get societeDebardage(): string {
		return this._societeDebardage;
	}

	public get contactDebardage(): string {
		return this._contactDebardage;
	}

	public get adresseDebardage(): string {
		return this._adresseDebardage;
	}

	public get cpDebardage(): string {
		return this._cpDebardage;
	}

	public get villeDebardage(): string {
		return this._villeDebardage;
	}

	public get telephoneDebardage(): string {
		return this._telephoneDebardage;
	}

	public get periodeDebardage(): string {
		return this._periodeDebardage;
	}

	public get consignesSecuriteIntervenants(): string {
		return this._consignesSecuriteIntervenants;
	}

	public get latitude(): number {
		return this._latitude;
	}

	public get longitude(): number {
		return this._longitude;
	}

	public get volume200(): number {
		return this._volume200;
	}

	public get surface(): number {
		return this._surface;
	}

	public get finDeCoupe(): boolean {
		return this._finDeCoupe;
	}

	public get boisFini(): boolean {
		return this._boisFini;
	}

	public get allBoisFini(): boolean {
		if ((this._avecBillons == true) && (this._billonsFini == false))
			return false;

		if ((this._avecGrumes == true) && (this._boisFini == false))
			return false;

		return true;
	}

	public get billonsFini(): boolean {
		return this._billonsFini;
	}

	public get infospe1(): boolean {
		return this._infospe1;
	}

	public get infospe2(): boolean {
		return this._infospe2;
	}

	public get infospe3(): boolean {
		return this._infospe3;
	}

	public get infospe4(): boolean {
		return this._infospe4;
	}

	public get infospe5(): boolean {
		return this._infospe5;
	}

	public get infospe6(): boolean {
		return this._infospe6;
	}

	public get infospe7(): boolean {
		return this._infospe7;
	}

	public get infospe8(): boolean {
		return this._infospe8;
	}

	public get infospe9(): boolean {
		return this._infospe9;
	}

	public get infospe10(): boolean {
		return this._infospe10;
	}

	public get infospe11(): boolean {
		return this._infospe11;
	}

	public get infospe12(): boolean {
		return this._infospe12;
	}

	public get infospe13(): boolean {
		return this._infospe13;
	}

	public get infospe14(): boolean {
		return this._infospe14;
	}

	public get infospe15(): boolean {
		return this._infospe15;
	}

	public get infospe16(): boolean {
		return this._infospe16;
	}

	public get infospe17(): boolean {
		return this._infospe17;
	}

	public get infospe18(): boolean {
		return this._infospe18;
	}

	public get infospe19(): boolean {
		return this._infospe19;
	}

	public get infospe20(): boolean {
		return this._infospe20;
	}

	public get infospe21(): boolean {
		return this._infospe21;
	}

	public get infospe22(): boolean {
		return this._infospe22;
	}

	public get infospe23(): boolean {
		return this._infospe23;
	}

	public get infospe24(): boolean {
		return this._infospe24;
	}

	public get infospe25(): boolean {
		return this._infospe25;
	}

	public get infospe26(): boolean {
		return this._infospe26;
	}

	public get infospe27(): boolean {
		return this._infospe27;
	}

	public get infospe28(): boolean {
		return this._infospe28;
	}

	public get valueinfospe28(): number {
		return this._valueinfospe28;
	}

	public get infospe29(): boolean {
		return this._infospe29;
	}

	public get infospe30(): boolean {
		return this._infospe30;
	}

	public get infospe31(): boolean {
		return this._infospe31;
	}

	public get infospe32(): boolean {
		return this._infospe32;
	}

	public get infospe33(): boolean {
		return this._infospe33;
	}

	public get couverturetelbonne(): boolean {
		return this._couverturetelbonne;
	}

	public get zonecouverte(): string {
		return this._zonecouverte;
	}

	public get ficheobservations(): string {
		return this._ficheobservations;
	}

	public get displayName(): string {
		/*let name = '';
		if (this._contact) {
			name += this._contact.fullName;
		} else {
			name += 'Aucun contact';
		}*/
		return this.name; //+' ('+name+')';
	}

	public get prestations(): Prestation[] {
		return this._prestations;
	}

	public get cubageChantiers(): CubageChantier[] {
		return this._cubageChantiers;
	}

	public get documents(): DocumentChantier[] {
		return this._documents;
	}

	public get photos(): DocumentChantier[] {
		return this._photos;
	}

	public get documentChauffeurs(): DocumentChantier[] {
		return this._documentChauffeurs;
	}

	public get abatteur(): Abatteur {
		return this._abatteur;
	}

	public get avecGrumes(): boolean {
		return this._avecGrumes;
	}

	public get grumesDebarde(): boolean {
		return this._grumesDebarde;
	}

	public get dtEndGrumes(): Date {
		return this._dtEndGrumes;
	}

	public get debardeur(): Debardeur {
		return this._debardeur;
	}

	public get avecBillons(): boolean {
		return this._avecBillons;
	}

	public get billonsDebarde(): boolean {
		return this._billonsDebarde;
	}

	public get dtEndBillons(): Date {
		return this._dtEndBillons;
	}

	public get debardeur2(): Debardeur {
		return this._debardeur2;
	}

	public get intervenants(): string {
		return this._intervenants;
	}

	public get boisTypes(): BoisType[] {
		return this._boisTypes;
	}

	public get boisQualities(): BoisQuality[] {
		return this._boisQualities;
	}

	public get boisSizes(): BoisSize[] {
		return this._boisSizes;
	}

	public get marquages(): Marquage[] {
		return this._marquages;
	}

	public get coutM3Defaut(): number {
		return this._coutM3Defaut;
	}

	public get cubagePlateforme(): CubagePlateforme {
		return this._cubagePlateforme;
	}

	/////////////
	// Setters //
	/////////////

	public set id(value: number) {
		this._id = value;
	}

	public set idTiers(value: number) {
		this._idTiers = value;
	}

	public set idPrestationType(value: number) {
		this._idPrestationType = value;
	}

	public set TypeAchat(value: string) {
		this._TypeAchat = value;
	}

	public set name(value: string ) {
		this._name = value;
	}

	public set fraisChantiers(value: FraisChantier[]) {
		this._fraisChantiers = value;
	}

	public set commune(value: string) {
		this._commune = value;
	}

	public set lieudit(value: string) {
		this._lieudit = value;
	}

	public set chauffeurParDefaut(value: Chauffeur) {
		this._chauffeurParDefaut = value;
	}

	public set messagePourChauffeur(value: string) {
		this._messagePourChauffeur = value;
	}

	public set messageOperateur(value: string) {
		this._messageOperateur = value;
	}

	public set client(value: string) {
		this._client = value;
	}

	public set payer(value: boolean) {
		this._payer = value;
	}

	public set paiementPreparer(value: boolean) {
		this._paiementPreparer = value;
	}

	/*public set contact(value: Contact) {
		this._contact = value;
	}*/

	public set consigne(value: string) {
		this._consigne = value;
	}

	public set dtStart(value: Date) {
		this._dtStart = value;
	}

	public set GPS(value: string) {
		this._GPS = value;
	}

	public set dtEnd(value: Date) {
		this._dtEnd = value;
	}

	public set dtEndCoupe(value: Date) {
		this._dtEndCoupe = value;
	}

	public set expectedDtStart(value: Date) {
		this._expectedDtStart = value;
	}

	public set expectedDtEnd(value: Date) {
		this._expectedDtEnd = value;
	}

	public set lien1(value: string) {
		this._lien1 = value;
	}

	public set lien12(value: string) {
		this._lien12 = value;
	}

	public set lien13(value: string) {
		this._lien13 = value;
	}

	public set lien2(value: string) {
		this._lien2 = value;
	}

	public set lienVersDrive(value: string) {
		this._lienVersDrive = value;
	}

	public set donneurOrdre(value: string) {
		this._donneurOrdre = value;
	}

	public set adresseDonneurOrdre(value: string) {
		this._adresseDonneurOrdre = value;
	}

	public set cpDonneurOrdre(value: string) {
		this._cpDonneurOrdre = value;
	}

	public set villeDonneurOrdre(value: string) {
		this._villeDonneurOrdre = value;
	}

	public set telephoneDonneurOrdre(value: string) {
		this._telephoneDonneurOrdre = value;
	}

	public set emailDonneurOrdre(value: string) {
		this._emailDonneurOrdre = value;
	}

	public set societeAbattage(value: string) {
		this._societeAbattage = value;
	}

	public set contactAbattage(value: string) {
		this._contactAbattage = value;
	}

	public set adresseAbattage(value: string) {
		this._adresseAbattage = value;
	}

	public set cpAbattage(value: string) {
		this._cpAbattage = value;
	}

	public set villeAbattage(value: string) {
		this._villeAbattage = value;
	}

	public set telephoneAbattage(value: string) {
		this._telephoneAbattage = value;
	}

	public set periodeAbattage(value: string) {
		this._periodeAbattage = value;
	}

	public set societeDebardage(value: string) {
		this._societeDebardage = value;
	}

	public set contactDebardage(value: string) {
		this._contactDebardage = value;
	}

	public set adresseDebardage(value: string) {
		this._adresseDebardage = value;
	}

	public set cpDebardage(value: string) {
		this._cpDebardage = value;
	}

	public set villeDebardage(value: string) {
		this._villeDebardage = value;
	}

	public set telephoneDebardage(value: string) {
		this._telephoneDebardage = value;
	}

	public set periodeDebardage(value: string) {
		this._periodeDebardage = value;
	}

	public set consignesSecuriteIntervenants(value: string) {
		this._consignesSecuriteIntervenants = value;
	}

	public set latitude(value: number) {
		value = <any>value !== '' ? value : null; // fix input
		this._latitude = value;
	}

	public set longitude(value: number) {
		value = <any>value !== '' ? value : null; // fix input
		this._longitude = value;
	}

	public set surface(value: number) {
		value = <any>value !== '' ? value : null; // fix input
		this._surface = value;
	}

	public set volume200(value: number) {
		value = <any>value !== '' ? value : null; // fix input
		this._volume200 = value;
	}

	public set finDeCoupe(value: boolean) {
		this._finDeCoupe = value;
	}

	public set boisFini(value: boolean) {
		this._boisFini = value;
	}

	public set billonsFini(value: boolean) {
		this._billonsFini = value;
	}

	public set infospe2(value: boolean) {
		this._infospe2 = value;
	}

	public set infospe3(value: boolean) {
		this._infospe3 = value;
	}

	public set infospe4(value: boolean) {
		this._infospe4 = value;
	}

	public set infospe5(value: boolean) {
		this._infospe5 = value;
	}

	public set infospe6(value: boolean) {
		this._infospe6 = value;
	}

	public set infospe7(value: boolean) {
		this._infospe7 = value;
	}

	public set infospe8(value: boolean) {
		this._infospe8 = value;
	}

	public set infospe9(value: boolean) {
		this._infospe9 = value;
	}

	public set infospe10(value: boolean) {
		this._infospe10 = value;
	}

	public set infospe11(value: boolean) {
		this._infospe11 = value;
	}

	public set infospe12(value: boolean) {
		this._infospe12 = value;
	}

	public set infospe13(value: boolean) {
		this._infospe13 = value;
	}

	public set infospe14(value: boolean) {
		this._infospe14 = value;
	}

	public set infospe15(value: boolean) {
		this._infospe15 = value;
	}

	public set infospe16(value: boolean) {
		this._infospe16 = value;
	}

	public set infospe17(value: boolean) {
		this._infospe17 = value;
	}

	public set infospe18(value: boolean) {
		this._infospe18 = value;
	}

	public set infospe19(value: boolean) {
		this._infospe19 = value;
	}

	public set infospe20(value: boolean) {
		this._infospe20 = value;
	}

	public set infospe21(value: boolean) {
		this._infospe21 = value;
	}

	public set infospe22(value: boolean) {
		this._infospe22 = value;
	}

	public set infospe23(value: boolean) {
		this._infospe23 = value;
	}

	public set infospe24(value: boolean) {
		this._infospe24 = value;
	}

	public set infospe25(value: boolean) {
		this._infospe25 = value;
	}

	public set infospe26(value: boolean) {
		this._infospe26 = value;
	}

	public set infospe27(value: boolean) {
		this._infospe27 = value;
	}

	public set infospe28(value: boolean) {
		this._infospe28 = value;
	}

	public set valueinfospe28(value: number) {
		value = <any>value !== '' ? value : null; // fix input
		this._valueinfospe28 = value;
	}

	public set infospe29(value: boolean) {
		this._infospe29 = value;
	}

	public set infospe30(value: boolean) {
		this._infospe30 = value;
	}

	public set infospe31(value: boolean) {
		this._infospe31 = value;
	}

	public set infospe32(value: boolean) {
		this._infospe32 = value;
	}

	public set infospe33(value: boolean) {
		this._infospe33 = value;
	}

	public set couverturetelbonne(value: boolean) {
		this._couverturetelbonne = value;
	}

	public set zonecouverte(value: string) {
		this._zonecouverte = value;
	}

	public set ficheobservations(value: string) {
		this._ficheobservations = value;
	}

	public set prestations(value: Prestation[]) {
		this._prestations = value;
	}

	public set cubageChantiers(value: CubageChantier[]) {
		this._cubageChantiers = value;
	}

	public set abatteur(value: Abatteur) {
		this._abatteur = value;
	}

	public set avecGrumes(value: boolean) {
		this._avecGrumes = value;
	}

	public set grumesDebarde(value: boolean) {
		this._grumesDebarde = value;
	}

	public set dtEndGrumes(value: Date) {
		this._dtEndGrumes = value;
	}

	public set debardeur(value: Debardeur) {
		this._debardeur = value;
	}

	public set avecBillons(value: boolean) {
		this._avecBillons = value;
	}

	public set billonsDebarde(value: boolean) {
		this._billonsDebarde = value;
	}

	public set dtEndBillons(value: Date) {
		this._dtEndBillons = value;
	}

	public set debardeur2(value: Debardeur) {
		this._debardeur2 = value;
	}

	public set intervenants(value: string) {
		this._intervenants = value;
	}

	public set coutM3Defaut(value: number) {
		this._coutM3Defaut = value;
	}

	public set totalAchatsBois(value: number) {
		this._totalAchatsBois = value;
	}

	public set totalCoutsExploitation(value: number) {
		this._totalCoutsExploitation = value;
	}

	public set totalVentes(value: number) {
		this._totalVentes = value;
	}

	public set benefices(value: number) {
		this._benefices = value;
	}

	public set financialClose(value: boolean) {
		this._financialClose = value;
	}

	public set cubagePlateforme(value: CubagePlateforme) {
		this._cubagePlateforme = value;
	}

	/////////
	// Add //
	/////////

	public addDocuments(document: DocumentChantier): this {
		document.chantier = this;
		if (this._documents.indexOf(document) === -1) {
			this._documents.push(document);
		}
		return this;
	}

	public addPhotos(document: DocumentChantier): this {
		document.chantier2 = this;
		if (this._photos.indexOf(document) === -1) {
			this._photos.push(document);
		}
		return this;
	}

	public addDocumentChauffeurs(document: DocumentChantier): this {
		document.chantier3 = this;
		if (this._documentChauffeurs.indexOf(document) === -1) {
			this._documentChauffeurs.push(document);
		}
		return this;
	}

	public removeDocuments(document: DocumentChantier): this {
		document.chantier = this;
		const index = this._documents.indexOf(document);
		if (index !== -1) {
			this._documents.splice(index, 1);
		}
		return this;
	}
	
	public removePhotos(document: DocumentChantier): this {
		document.chantier2 = this;
		const index = this._photos.indexOf(document);
		if (index !== -1) {
			this._photos.splice(index, 1);
		}
		return this;
	}
	
	public removeDocumentChauffeurs(document: DocumentChantier): this {
		document.chantier3 = this;
		const index = this._documentChauffeurs.indexOf(document);
		if (index !== -1) {
			this._documentChauffeurs.splice(index, 1);
		}
		return this;
	}
	
	///////////////
	// Serialize //
	///////////////

	public toJSON(): any {
		return {
			id             : this._id,
			name           : this._name,
			payer        : this._payer,
			paiementPreparer        : this._paiementPreparer,
			commune  	  : this._commune,
			lieudit  	  : this._lieudit,
			GPS  	  : this._GPS,
			chauffeurParDefaut  : this._chauffeurParDefaut ? this._chauffeurParDefaut.id : null,
			messagePourChauffeur  	  : this._messagePourChauffeur,
			messageOperateur  	  : this._messageOperateur,
			client  	  : this._client,
			consigne       : this._consigne,
			dtStart        : this._dtStart         ? DateHelper.toLocalizedString(this._dtStart)         : null,
			dtEnd          : this._dtEnd           ? DateHelper.toLocalizedString(this._dtEnd)           : null,
			dtEndCoupe          : this._dtEndCoupe           ? DateHelper.toLocalizedString(this._dtEndCoupe)           : null,
			expectedDtStart: this._expectedDtStart ? DateHelper.toLocalizedString(this._expectedDtStart) : null,
			expectedDtEnd  : this._expectedDtEnd   ? DateHelper.toLocalizedString(this._expectedDtEnd)   : null,
			lien1          : this._lien1,
			lien12          : this._lien12,
			lien13          : this._lien13,
			lien2          : this._lien2,
			lienVersDrive          : this._lienVersDrive,
			donneurOrdre   : this._donneurOrdre,
			adresseDonneurOrdre   : this._adresseDonneurOrdre,
			CPDonneurOrdre   : this._cpDonneurOrdre,
			villeDonneurOrdre   : this._villeDonneurOrdre,
			telephoneDonneurOrdre   : this._telephoneDonneurOrdre,
			emailDonneurOrdre   : this._emailDonneurOrdre,
			societeAbattage   : this._societeAbattage,
			contactAbattage   : this._contactAbattage,
			adresseAbattage   : this._adresseAbattage,
			CPAbattage   : this._cpAbattage,
			villeAbattage   : this._villeAbattage,
			telephoneAbattage   : this._telephoneAbattage,
			periodeAbattage  : this._periodeAbattage,
			societeDebardage   : this._societeDebardage,
			contactDebardage   : this._contactDebardage,
			adresseDebardage   : this._adresseDebardage,
			CPDebardage   : this._cpDebardage,
			villeDebardage   : this._villeDebardage,
			telephoneDebardage   : this._telephoneDebardage,
			periodeDebardage  : this._periodeDebardage,
			consignessecuriteintervenants  : this._consignesSecuriteIntervenants,
			latitude  : this._latitude,
			longitude : this._longitude,
			surface   : this._surface,
			volume200 : this._volume200,
			finDeCoupe: this._finDeCoupe,
			boisFini: this._boisFini,
			billonsFini: this._billonsFini,
			infospe1  : this._infospe1,
			infospe2  : this._infospe2,
			infospe3  : this._infospe3,
			infospe4  : this._infospe4,
			infospe5  : this._infospe5,
			infospe6  : this._infospe6,
			infospe7  : this._infospe7,
			infospe8  : this._infospe8,
			infospe9  : this._infospe9,
			infospe10  : this._infospe10,
			infospe11  : this._infospe11,
			infospe12 : this._infospe12,
			infospe13  : this._infospe13,
			infospe14  : this._infospe14,
			infospe15  : this._infospe15,
			infospe16  : this._infospe16,
			infospe17  : this._infospe17,
			infospe18  : this._infospe18,
			infospe19  : this._infospe19,
			infospe20  : this._infospe20,
			infospe21  : this._infospe21,
			infospe22  : this._infospe22,
			infospe23  : this._infospe23,
			infospe24  : this._infospe24,
			infospe25  : this._infospe25,
			infospe26  : this._infospe26,
			infospe27  : this._infospe27,
			infospe28  : this._infospe28,
			valueinfospe28  : this._valueinfospe28,
			infospe29  : this._infospe29,
			infospe30  : this._infospe30,
			infospe31  : this._infospe31,
			infospe32  : this._infospe32,
			infospe33  : this._infospe33,
			zonecouverte  : this._zonecouverte,
			intervenants  : this._intervenants,
			couverturetelbonne  : this._couverturetelbonne,
			ficheobservations  : this._ficheobservations,
			abatteur: this._abatteur ? this._abatteur.id : null,
			avecGrumes: this._avecGrumes,
			grumesDebarde: this._grumesDebarde,
			dtEndGrumes: this._dtEndGrumes         ? DateHelper.toLocalizedString(this._dtEndGrumes)         : null,
			debardeur: this._debardeur ? this._debardeur.id : null,
			avecBillons: this._avecBillons,
			billonsDebarde: this._billonsDebarde,
			dtEndBillons: this._dtEndBillons         ? DateHelper.toLocalizedString(this._dtEndBillons)         : null,
			debardeurdeux: this._debardeur2 ? this._debardeur2.id : null,
			coutM3Defaut  : this._coutM3Defaut,
			totalAchatsBois  : this._totalAchatsBois,
			totalCoutsExploitation  : this._totalCoutsExploitation,
			totalVentes  : this._totalVentes,
			benefices  : this._benefices,
			financialClose  : this._financialClose,
			fraisChantiers : this._fraisChantiers ? this._fraisChantiers.map(r => r.toJSON()) : [],
			cubagePlateforme  : this._cubagePlateforme ? this._cubagePlateforme.id : null,

			idTiers  : this._idTiers,
			idPrestationType  : this._idPrestationType,
			typeAchat  : this._TypeAchat,
		};
	}

	public fromJSON(data: any): this {
		this._id              = data.id;
		this._name            = data.name;
		this._commune               = data.commune;
		this._lieudit               = data.lieudit;
		this._GPS               = data.GPS;
		this._chauffeurParDefaut    = data.chauffeurParDefaut ? (new Chauffeur()).fromJSON(data.chauffeurParDefaut) : null;
		this._messagePourChauffeur  = data.messagePourChauffeur;
		this._messageOperateur  = data.messageOperateur;
		this._client               	= data.client;
		this._payer                 = data.payer;
		this._paiementPreparer        = data.paiementPreparer;
		this._consigne        = data.consigne;
		this._dtStart         = data.dtStart         ? DateHelper.fromLocalizedString(data.dtStart)        : null;
		this._dtEnd           = data.dtEnd           ? DateHelper.fromLocalizedString(data.dtEnd)          : null;
		this._dtEndCoupe           = data.dtEndCoupe           ? DateHelper.fromLocalizedString(data.dtEndCoupe)          : null;
		this._expectedDtStart = data.expectedDtStart ? DateHelper.fromLocalizedString(data.expectedDtStart): null;
		this._expectedDtEnd   = data.expectedDtEnd   ? DateHelper.fromLocalizedString(data.expectedDtEnd)  : null;
		this._lien1           = data.lien1;
		this._lien12           = data.lien12;
		this._lien13           = data.lien13;
		this._lien2           = data.lien2;
		this._lienVersDrive           = data.lienVersDrive;
		this._prestations     = data.prestations ? data.prestations.map(p => (new Prestation()).fromJSON(p)) : [];
		this._cubageChantiers     = data.cubageChantiers ? data.cubageChantiers.map(c => (new CubageChantier()).fromJSON(c)) : [];
		this._donneurOrdre    = data.donneurOrdre;
		this._adresseDonneurOrdre    = data.adresseDonneurOrdre;
		this._cpDonneurOrdre    = data.CPDonneurOrdre;
		this._villeDonneurOrdre    = data.villeDonneurOrdre;
		this._telephoneDonneurOrdre    = data.telephoneDonneurOrdre;
		this._emailDonneurOrdre    = data.emailDonneurOrdre;
		this._societeAbattage    = data.societeAbattage;
		this._contactAbattage    = data.contactAbattage;
		this._adresseAbattage    = data.adresseAbattage;
		this._cpAbattage    = data.CPAbattage;
		this._villeAbattage    = data.villeAbattage;
		this._telephoneAbattage    = data.telephoneAbattage;
		this._periodeAbattage    = data.periodeAbattage;
		this._societeDebardage    = data.societeDebardage;
		this._contactDebardage    = data.contactDebardage;
		this._adresseDebardage    = data.adresseDebardage;
		this._cpDebardage    = data.CPDebardage;
		this._villeDebardage    = data.villeDebardage;
		this._telephoneDebardage    = data.telephoneDebardage;
		this._periodeDebardage    = data.periodeDebardage;
		this._consignesSecuriteIntervenants    = data.consignessecuriteintervenants;
		this._latitude    = data.latitude;
		this._longitude    = data.longitude;
		this._surface    = data.surface;
		this._volume200    = data.volume200;
		this._finDeCoupe    = data.finDeCoupe;
		this._boisFini    = data.boisFini;
		this._billonsFini    = data.billonsFini;
		this._infospe1    = data.infospe1;
		this._infospe2    = data.infospe2;
		this._infospe3    = data.infospe3;
		this._infospe4    = data.infospe4;
		this._infospe5    = data.infospe5;
		this._infospe6    = data.infospe6;
		this._infospe7    = data.infospe7;
		this._infospe8    = data.infospe8;
		this._infospe9    = data.infospe9;
		this._infospe10    = data.infospe10;
		this._infospe11    = data.infospe11;
		this._infospe12    = data.infospe12;
		this._infospe13    = data.infospe13;
		this._infospe14    = data.infospe14;
		this._infospe15    = data.infospe15;
		this._infospe16    = data.infospe16;
		this._infospe17    = data.infospe17;
		this._infospe18    = data.infospe18;
		this._infospe19    = data.infospe19;
		this._infospe20    = data.infospe20;
		this._infospe21    = data.infospe21;
		this._infospe22    = data.infospe22;
		this._infospe23    = data.infospe23;
		this._infospe24    = data.infospe24;
		this._infospe25    = data.infospe25;
		this._infospe26    = data.infospe26;
		this._infospe27    = data.infospe27;
		this._infospe28    = data.infospe28;
		this._valueinfospe28    = data.valueinfospe28;
		this._infospe29    = data.infospe29;
		this._infospe30    = data.infospe30;
		this._infospe31    = data.infospe31;
		this._infospe32    = data.infospe32;
		this._infospe33    = data.infospe33;
		this._intervenants    = data.intervenants;
		this._zonecouverte    = data.zonecouverte;
		this._ficheobservations    = data.ficheobservations;
		this._couverturetelbonne   = data.couverturetelbonne;
		this._abatteur  = data.abatteur  ? (new Abatteur()).fromJSON(data.abatteur)   : null;
		this._avecGrumes    = data.avecGrumes;
		this._grumesDebarde    = data.grumesDebarde;
		this._dtEndGrumes    = data.dtEndGrumes         ? DateHelper.fromLocalizedString(data.dtEndGrumes)        : null;
		this._debardeur = data.debardeur ? (new Debardeur()).fromJSON(data.debardeur) : null;
		this._avecBillons    = data.avecBillons;
		this._billonsDebarde    = data.billonsDebarde;
		this._dtEndBillons    = data.dtEndBillons         ? DateHelper.fromLocalizedString(data.dtEndBillons)        : null;
		this._debardeur2 = data.debardeurdeux ? (new Debardeur()).fromJSON(data.debardeurdeux) : null;
		this._boisTypes = data.boisTypes ? data.boisTypes.map(b => (new BoisType()).fromJSON(b)) : [];
		this._boisQualities = data.boisQualities ? data.boisQualities.map(b => (new BoisQuality()).fromJSON(b)) : [];
		this._boisSizes = data.boisSizes ? data.boisSizes.map(b => (new BoisSize()).fromJSON(b)) : [];
		this._marquages = data.marquages ? data.marquages.map(b => (new Marquage()).fromJSON(b)) : [];
		if (data.documentChantiers)  {
			for (const d of data.documentChantiers) {
				this.addDocuments((new DocumentChantier()).fromJSON(d));
			}
		}
		if (data.photoChantiers)  {
			for (const d of data.photoChantiers) {
				this.addPhotos((new DocumentChantier()).fromJSON(d));
			}
		}
		if (data.documentChauffeurs)  {
			for (const d of data.documentChauffeurs) {
				this.addDocumentChauffeurs((new DocumentChantier()).fromJSON(d));
			}
		}
		this._coutM3Defaut    = data.coutM3Defaut;
		this._totalAchatsBois    = data.totalAchatsBois;
		this._totalCoutsExploitation    = data.totalCoutsExploitation;
		this._totalVentes    = data.totalVentes;
		this._benefices    = data.benefices;
		this._financialClose    = data.financialClose;
		this._fraisChantiers                = data.fraisChantiers ? data.fraisChantiers.map(r => (new FraisChantier()).fromJSON(r)) : [];
		this._cubagePlateforme    = data.cubagePlateforme ? (new CubagePlateforme()).fromJSON(data.cubagePlateforme) : null;
		return this;
	}
}
