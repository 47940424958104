import Vue from 'vue';
import Vuex, {ActionContext} from 'vuex';
import {AscDesc, ResultType, XHTTPService} from '@/shared/xhttp';
import {
	PaginatorActionWithFilters,
	PaginatorFilterState,
	PaginatorMutationFilter,
	StandardAction,
} from '@/shared/utils';
import {Purchase, Tiers} from '@/models';
import {PurchaseService} from '@/xhttp';
import {DateFilter} from '@/shared/filter';

import store from '@/stores';

Vue.use(Vuex);

export class PurchaseFilter {

	public date: DateFilter = new DateFilter();
	public numero: string = '';
	public reference: string = '';
	public end: boolean[] = [];
	public refund: boolean[] = [];
	public tiers: Tiers[] = [];
	public createdAt: DateFilter = new DateFilter();

	public toString(): string {
		return JSON.stringify({
			date       : this.date.toJSON(),
			numero     : this.numero,
			reference  : this.reference,
			end        : this.end,
			refund     : this.refund,
			tiers      : this.tiers.map(t => t.id),
			createdAt  : this.createdAt.toJSON(),
		});
	}
}

class PurchaseState extends PaginatorFilterState<PurchaseFilter, Purchase> {
	purchase: Purchase = null;
	purchases: ResultType<Purchase> = null;
	public constructor() {
		super(new PurchaseFilter(), 'purchases', 'purchase', 'id', AscDesc.DESC, 20);
	}
}

class PurchaseStore {

	@XHTTPService(() => Purchase)
	private purchaseService: PurchaseService;

	public state: PurchaseState = new PurchaseState();

	public mutations = {
		...PaginatorMutationFilter<Purchase>(() => storePurchase, 'purchases', 'purchase'),
	};

	public actions = {
		...StandardAction<Purchase, PurchaseState>(() => Purchase, 'purchase'),
		...PaginatorActionWithFilters<Purchase, PurchaseState>(() => Purchase, 'purchases', 'purchase'),

		patchEnd(context: ActionContext<PurchaseState, any>, purchase: Purchase): Promise<Purchase> {
			return instance.purchaseService.patchEnd(purchase);
		},

		patchRefund(context: ActionContext<PurchaseState, any>, purchase: Purchase): Promise<Purchase> {
			return instance.purchaseService.patchRefund(purchase);
		},

		async patchPrices(context: ActionContext<PurchaseState, any>, purchase: Purchase): Promise<Purchase> {
			purchase = await instance.purchaseService.patchPrices(purchase);
			context.commit('setPurchase', purchase);
			return purchase;
		},
	};

}
const instance = new PurchaseStore();
const storePurchase = new Vuex.Store(instance);
store.registerModule('purchase', storePurchase);
export default storePurchase;
