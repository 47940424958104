

















































































	import {Prop, Watch} from "vue-property-decorator";
	import {Store} from "vuex";
	import {Component, Vue} from '@/shared/component';
	import {Timeout, WatchObjectProperties} from '@/shared/decorator';
	import {Chantier, TiersType} from "@/models";

	import storePrestation, {PrestationFilter, PrestationState} from '@/stores/modules/prestation';

	import InputNumberFilter from '@/components/Input/NumberFilter.vue';
	import TrueFalseMultiSelect from '@/components/Input/TrueFalseMultiSelect.vue';
	import CrudContratSearch from '@/components/Crud/Contrat/Search.vue';
	import InputPrestationType from '@/components/Input/PrestationType.vue';
	import CrudTiersSearch from "@/components/Crud/Tiers/Search.vue";

	@Component({
		components: {
			InputNumberFilter,
			TrueFalseMultiSelect,
			CrudContratSearch,
			InputPrestationType,
			CrudTiersSearch,
		}
	})
	export default class CrudPrestationFilter extends Vue {

		@Prop({ type: Boolean, default: false}) reduce;
		@Prop({ type: Boolean, default: false}) hideContrat;
		@Prop({type: Chantier, default: null }) public chantier!: Chantier
		@Prop({type: Store   , default: null }) public store!  : Store<PrestationState>;
        @Prop({ type: Boolean, default: false }) public contratText!: boolean;
		@Prop({ type: Array, default: () => [] }) public hasChantier!: boolean[];

		private TiersType = TiersType;

		public get currentStore(): Store<PrestationState> {
			return <any>(this.store ? this.store : storePrestation);
		}

		public get page(): number {
			return this.currentStore.state.page;
		}

		public get filters(): PrestationFilter {
			return this.currentStore.state.filters;
		}

		public set page(value: number) {
			this.currentStore.commit('setPage', value);
		}

		public set filters(value: PrestationFilter) {
			this.currentStore.commit('setFilters', value);
		}

		@WatchObjectProperties('filters', PrestationFilter)
		@Timeout()
		private filtersWatcher(): void {
			this.page = 0;
			this.currentStore.dispatch('getC', { filters: true });
		}

		public async mounted(): Promise<void> {
			if (this.reduce) {
				this.filters.contrat = [];
				this.filters.finish = [];
			}
			if (this.hasChantier) {
				this.filters.hasChantier = this.hasChantier;
			}
			this.chantierWatcher();
		}
		
		@Watch('chantier')
		private chantierWatcher(): void {
			this.filters.chantier = this.chantier ? [ this.chantier ]: [];
		}
	}
