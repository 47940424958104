













import {Store} from "vuex";
import {Prop} from "vue-property-decorator";
import {InOut} from 'vue-inout-prop-decorator';
import {Component, Vue} from '@/shared/component';
import {PrestationType} from '@/models';
import {PaginatorState} from "@/shared/utils";

@Component()
export default class InputGenericEntitySelect extends Vue {

	@Prop ({ type: Store , required: true }) private store!: Store<PaginatorState>;
	@Prop ({ type: Function , required: false, default: () => ( () => true ) }) private itemFilter!: () => boolean;
	@Prop({ type: Boolean, default: false }) multiple!: boolean;
	@InOut({ type: [ Object, Array ], isVModel: true }) private value!: any;

	private get items(): PrestationType[] {
		if (this.multiple) {
			if (this.store.state.all) {
				const list = this.store.state.all.data.concat([]);
				for (const v of this.value) {
					if (v && !this.store.state.all.data.filter(pt => v.id === pt.id).length) {
						list.push(v);
					}
				}
				return list.filter(this.itemFilter);
			}
			return this.value.concat([]);
		} else {
			if (this.store.state.all) {
				if (this.value && !this.store.state.all.data.filter(pt => pt.id === this.value.id).length) {
					return this.store.state.all.data.concat([ this.value ]).filter(this.itemFilter);
				}
				return this.store.state.all.data.filter(this.itemFilter);
			}
			if (this.value) {
				return [ this.value ].filter(this.itemFilter);
			}
		}
		return [];
	}
	
	public async mounted(): Promise<void> {
		await this.store.dispatch('getAll');
	}
}
