import {BoisType} from '@/models';

export default [
	{
		path: '/bois-type',
		name: 'boisType',
		meta: {
			title: 'Liste des types de bois',
			granted: [ 'API_GET_BOISTYPE' ]
		},
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/BoisType.vue')
	},
	{
		path: '/boisType/edit/:id',
		name: 'boisType-edit',
		props: {
			slotData: () => ({
				component: import(/* webpackChunkName: "dashboard" */ '../components/Crud/BoisType/Edit.vue'),
				class: BoisType
			}),
		},
		meta: {
			title: 'Edition d\'un type de bois',
			granted: [ 'API_PUT_BOISTYPE' ]
		},
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/EditShow.vue')
	},
];