






























	import {Prop, Watch} from "vue-property-decorator";
	import {Stored} from 'vue-stored-prop-decorator';
	import {Component, Vue} from '@/shared/component';
	import {Timeout, WatchObjectProperties} from '@/shared/decorator';

	import storeTiers, {TiersFilter} from '@/stores/modules/tiers';

	import InputDateFilter from '@/components/Input/DateFilter.vue';
	import {TiersType} from "@/models";

	@Component({
		components: {
			InputDateFilter,
		}
	})
	export default class CrudTiersFilterReduce extends Vue {

		@Prop({ type: Boolean, default: false}) reduce!: boolean;
		@Prop({ type: Array, default: () => []}) type!: TiersType[];

		@Stored(() => storeTiers) private page!: number;
		@Stored(() => storeTiers) private filters!: TiersFilter;

		@WatchObjectProperties('filters', TiersFilter)
		@Timeout()
		private filtersWatcher(): void {
			this.page = 0;
			storeTiers.dispatch('getC', { filters: true });
		}
		
		public mounted() {
		    this.typeWatcher();
		}

	    @Watch('type')
		private typeWatcher(): void {
            this.filters.clear();
			this.filters.type = this.type || [ TiersType.PROPRIETAIRE ];
		}
		
	}
