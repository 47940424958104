import {BoisQuality} from '@/models';

export default [
	{
		path: '/bois-quality',
		name: 'boisQuality',
		meta: {
			title: 'Liste une qualité de bois',
			granted: [ 'API_GET_BOISQUALITY' ]
		},
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/BoisQuality.vue')
	},
	{
		path: '/boisQuality/edit/:id',
		name: 'boisQuality-edit',
		props: {
			slotData: () => ({
				component: import(/* webpackChunkName: "dashboard" */ '../components/Crud/BoisQuality/Edit.vue'),
				class: BoisQuality
			}),
		},
		meta: {
			title: 'Edition d\'une qualité de bois',
			granted: [ 'API_PUT_BOISQUALITY' ]
		},
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/EditShow.vue')
	},
];