import Vue from 'vue';
import Vuex, {ActionContext} from 'vuex';
import {AscDesc, ResultType, XHTTPService} from '@/shared/xhttp';
import {PaginatorActionWithFilters, PaginatorFilterState, PaginatorMutation, PaginatorState} from '@/shared/utils';
import {Abatteur, Chantier, Contact, Debardeur} from '@/models';
import {ChantierService} from '@/xhttp';
import store from '@/stores';

import {DateFilter} from '@/shared/filter';
import {StandardAction} from '@/shared/utils/Store/StandardAction';
import {SetterMutation} from '@/shared/utils/Store';
import {PrestationState} from '@/stores/modules/prestation';

Vue.use(Vuex);

export class ChantierFilter {

	public name           : string = "";
	public client        : string = "";
	public commune        : string = "";
	public contact        : Contact[] = [];
	public payer	      : boolean[] = [];
	public finDeCoupe     : boolean[] = [];
	public boisFini     : boolean[] = [];
	public dtStart        : DateFilter = new DateFilter();
	public dtEnd          : DateFilter = new DateFilter();
	public expectedDtStart: DateFilter = new DateFilter();
	public expectedDtEnd  : DateFilter = new DateFilter();
	public abatteur       : Abatteur[] = [];
	public debardeur      : Debardeur[] = [];
	public debardeur2      : Debardeur[] = [];
	public intervenants   : string = "";
	public financialClose	      : boolean[] = [];

	public toString(): string {
		return JSON.stringify({
			name           : this.name,
			client        : this.client,
			commune        : this.commune,
			contact        : this.contact.map(t => t.id),
			dtStart        : this.dtStart,
			dtEnd          : this.dtEnd,
			expectedDtStart: this.expectedDtStart,
			expectedDtEnd  : this.expectedDtEnd,
			payer     		: this.payer,
			finDeCoupe     : this.finDeCoupe,
			boisFini     : this.boisFini,
			abatteur       : this.abatteur.map(a => a.id),
			debardeur      : this.debardeur.map(d => d.id),
			debardeurdeux      : this.debardeur2.map(d => d.id),
			intervenants   : this.intervenants,
			financialClose : this.financialClose,
		});
	}
}

class ChantierState extends PaginatorFilterState<ChantierFilter> {
	public chantier: ResultType<Chantier> = null;
	public chantiers: ResultType<Chantier> = null;
	public constructor() {
		super(new ChantierFilter(), 'chantiers', 'chantier', 'id', AscDesc.DESC, 100);
	}
}

class ChantierStore {

	@XHTTPService(() => Chantier)
	private chantierService: ChantierService;

	public state: ChantierState = new ChantierState();

	public mutations = {
		...PaginatorMutation(() => storeChantier, 'chantiers', 'chantier'),
		...SetterMutation(['chantiers', 'filters']),
	};

	public actions = {
		...StandardAction<Chantier, ChantierState>(() => Chantier, 'chantier'),
		...PaginatorActionWithFilters<Chantier, ChantierState>(() => Chantier, 'chantiers', 'chantier'),

		putMarkPaid(context: ActionContext<ChantierState, any>, chantier: Chantier) {
			return instance.chantierService.putMarkPaid(chantier);
		},
		putMarkDebut(context: ActionContext<ChantierState, any>, chantier: Chantier) {
			return instance.chantierService.putMarkDebut(chantier);
		},
		putMarkFin(context: ActionContext<ChantierState, any>, chantier: Chantier) {
			return instance.chantierService.putMarkFin(chantier);
		},
		putMarkAvecGrumes(context: ActionContext<ChantierState, any>, chantier: Chantier) {
			return instance.chantierService.putMarkAvecGrumes(chantier);
		},
		putMarkAvecBillons(context: ActionContext<ChantierState, any>, chantier: Chantier) {
			return instance.chantierService.putMarkAvecBillons(chantier);
		},

		putMarkDebardageGrumes(context: ActionContext<ChantierState, any>, chantier: Chantier) {
			return instance.chantierService.putMarkDebardageGrumes(chantier);
		},
		putMarkDebardageBillons(context: ActionContext<ChantierState, any>, chantier: Chantier) {
			return instance.chantierService.putMarkDebardageBillons(chantier);
		},

		putMarkFinCoupe(context: ActionContext<ChantierState, any>, chantier: Chantier) {
			return instance.chantierService.putMarkFinCoupe(chantier);
		},
		putMarkBoisFini(context: ActionContext<ChantierState, any>, chantier: Chantier) {
			return instance.chantierService.putMarkBoisFini(chantier);
		},
		putMarkBillonsFini(context: ActionContext<ChantierState, any>, chantier: Chantier) {
			return instance.chantierService.putMarkBillonsFini(chantier);
		},
		putCoutM3(context: ActionContext<ChantierState, any>, chantier: Chantier) {
			return instance.chantierService.putCoutM3(chantier);
		},
		calculBilan(context: ActionContext<ChantierState, any>, chantier: Chantier) {
			return instance.chantierService.calculBilan(chantier);
		},
		setChauffeurCubages(context: ActionContext<ChantierState, any>, chantier: Chantier) {
			return instance.chantierService.setChauffeurCubages(chantier);
		},
		async putMarkPayPrepared(context: ActionContext<ChantierState, any>, chantier: Chantier) {
			let instancePrestation = await instance.chantierService.putMarkPayPrepared(chantier)
			return instancePrestation;
		},
	};

}
const instance = new ChantierStore();
const storeChantier = new Vuex.Store(instance);
store.registerModule('chantier', storeChantier);
export default storeChantier;
