import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import i18n from './shared/i18n';
import store from './stores';
import './shared/directives';
import './shared/filters';
import './shared/leaflet';
import './shared/svgcharts';

import vuetify from './shared/vuetify';
import './shared/form';

Vue.config.devtools = true;
Vue.config.productionTip = false;

new Vue({
    i18n: i18n,
    router: router,
    store: store,
    vuetify: vuetify,
    render: h => h(App)
}).$mount('#app');
