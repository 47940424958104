






























































import {Prop, Watch} from "vue-property-decorator";
import {Component, Vue} from '@/shared/component';
import {Stored} from "vue-stored-prop-decorator";
import {Tiers} from "@/models";

import storeContrat, {ContratFilter} from '@/stores/modules/contrat';

import CrudList from '@/components/Crud/List.vue';
import TableHead from '@/components/Paginate/TableHead.vue';
import TableCell from '@/components/Paginate/TableCell.vue';

@Component({
	components: {
		CrudList,
		TableHead,
		TableCell,
	}
})
export default class CrudContratList extends Vue {

	@Prop({type: Boolean, default: true  }) public paginate!: boolean;
	@Prop({type: Boolean, default: false }) public dense!   : boolean;
	
	@Prop({type: [ Boolean, Tiers ], default: false }) private tiers!: Tiers|boolean;

	@Stored(() => storeContrat)
	private filters: ContratFilter;
	
	private storeContrat = storeContrat;

	public mounted(): void {
		this.tiersWatcher();
	}

	@Watch('tiers')
	private tiersWatcher(): void {
		if (this.tiers) {
			this.filters.tiers = [ <Tiers>this.tiers ];
		}
	}
}
