import {XHTTP} from '@/shared/xhttp';
import {BoisSizeService} from '@/xhttp';

@XHTTP(BoisSizeService, '/bois-sizes')
export class BoisSize {
	
	private _id: number;
	private _name: string;
	private _grume: boolean;
	private _use: boolean;
	
	/////////////
	// Getters //
	/////////////
	
	public get id(): number {
		return this._id;
	}

	public get name(): string {
		return this._name;
	}

	public get grume(): boolean {
		return this._grume;
	}
	
	public get use(): boolean {
		return this._use;
	}
	
	/////////////
	// Setters //
	/////////////

	public set id(value: number) {
		this._id = value;
	}

	public set grume(value: boolean) {
		this._grume = value;
	}

	public set name(value: string) {
		this._name = value;
	}

	///////////////
	// Serialize //
	///////////////

	public toJSON(): any {
		return {
			id     : this._id,
			name   : this._name,
			grume   : this._grume,
		};
	}
	
	public fromJSON(data: any): this {
		this._id   = data.id;
		this._name = data.name;
		this._grume = data.grume;
		this._use  = data.use;
		return this;
	}

	////////////
	// Others //
	////////////
	
	public toString(): string {
		return this.name;
	}
	
}