import {GenericService} from '@/shared/xhttp';
import {Chantier} from '@/models';

export class ChantierService extends GenericService<Chantier> {
	public putMarkPayPrepared(chantier: Chantier) {
		this.put(`/${chantier.id}/mark-PayPrepared`);
	}

	public putMarkPaid(chantier: Chantier) {
		this.put(`/${chantier.id}/mark-paid`);
	}
	
	public putMarkDebut(chantier: Chantier) {
		this.put(`/${chantier.id}/mark-debut`);
	}

	public putMarkAvecGrumes(chantier: Chantier) {
		this.put(`/${chantier.id}/mark-avecgrumes`);
	}

	public putMarkDebardageGrumes(chantier: Chantier) {
		this.put(`/${chantier.id}/mark-debardagegrumes`);
	}

	public putMarkDebardageBillons(chantier: Chantier) {
		this.put(`/${chantier.id}/mark-debardagebillons`);
	}

	public putMarkAvecBillons(chantier: Chantier) {
		this.put(`/${chantier.id}/mark-avecbillons`);
	}

	public putMarkFin(chantier: Chantier) {
		this.put(`/${chantier.id}/mark-fin`);
	}

	public putMarkFinCoupe(chantier: Chantier) {
		this.put(`/${chantier.id}/mark-fincoupe`);
	}

	public putMarkBoisFini(chantier: Chantier) {
		this.put(`/${chantier.id}/mark-boisfini`);
	}

	public putMarkBillonsFini(chantier: Chantier) {
		this.put(`/${chantier.id}/mark-billonsfini`);
	}

	public setChauffeurCubages(chantier: Chantier) {
		this.put(`/${chantier.id}/setchauffeurcubages`);
	}

	public putCoutM3(chantier: Chantier) {
		this.put(`/${chantier.id}/put-coutm3`);
	}
	
	public calculBilan(chantier: Chantier) {
		this.put(`/${chantier.id}/calcul-bilan`);
	}
}
