import {XHTTP} from '@/shared/xhttp';
import {PrestationTypeService} from '@/xhttp';

@XHTTP(PrestationTypeService, '/prestation-types')
export class PrestationType {
	
	private _id: number;
	private _name: string;
	private _sale: boolean;
	private _use: boolean;
	
	/////////////
	// Getters //
	/////////////
	
	get id(): number {
		return this._id;
	}

	public get name(): string {
		return this._name;
	}

	public get sale(): boolean {
		return this._sale;
	}

	public get use(): boolean {
		return this._use;
	}
	
	/////////////
	// Setters //
	/////////////

	public set name(value: string) {
		this._name = value;
	}

	public set sale(value: boolean) {
		this._sale = value;
	}

	///////////////
	// Serialize //
	///////////////

	public toJSON(): any {
		return {
			id     : this._id,
			name   : this._name,
			sale: this._sale,
		};
	}
	
	public fromJSON(data: any): this {
		this._id   = data.id;
		this._name = data.name;
		this._sale = data.sale;
		this._use  = data.use;
		return this;
	}

	////////////
	// Others //
	////////////
	
	public toString(): string {
		return this.name;
	}
	
}