











































































import {Prop} from "vue-property-decorator";
import {Stored} from 'vue-stored-prop-decorator';
import {Component, Vue} from '@/shared/component';
import {Timeout, WatchObjectProperties} from '@/shared/decorator';
import {ContratState, TiersType} from '@/models';

import storeContrat, {ContratFilter} from '@/stores/modules/contrat';

import InputDateFilter from '@/components/Input/DateFilter.vue';
import CrudTiersSearch from '@/components/Crud/Tiers/Search.vue';

@Component({
	components: {
		InputDateFilter,
		CrudTiersSearch,
	}
})
export default class CrudContratFilter extends Vue {

	@Prop({ type: Boolean, default: false}) public reduce!: boolean;

	@Stored(() => storeContrat) private page!: number;
	@Stored(() => storeContrat) private filters!: ContratFilter;
	
	private TiersType = TiersType;
	private ContratState = ContratState;
	
	@WatchObjectProperties('filters', ContratFilter)
	@Timeout()
	private filtersWatcher(): void {
		this.page = 0;
		storeContrat.dispatch('getC', { filters: true });
	}

	public mounted() {
		if (this.reduce) {
			this.filters.clear();
		}
	}
}
