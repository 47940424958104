import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import '@/assets/scss/vuetify/index.scss';
import fr from 'vuetify/src/locale/fr';

Vue.use(Vuetify);

export default new Vuetify({
    lang: {
      locales: { fr },
      current: 'fr',
    },
    theme: {
        themes: {
            light: {
                primary: '#00966a',
                secondary: '#2196f3',
                accent: '#e91e63',
                error: '#f44336',
                warning: '#ff9800',
                info: '#ffc107',
                success: '#93c342'
            }
        }
    },
});
