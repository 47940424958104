import {XHTTP} from '@/shared/xhttp';
import {CubagePlateformeService} from '@/xhttp';
import {DateHelper} from "@/shared/utils";
import {BoisType} from './BoisType';
import {BoisQuality} from './BoisQuality';
import {BoisSize} from './BoisSize';
import {Marquage} from './Marquage';
import {Chantier} from './Chantier';
import {Purchase} from "./Purchase";
import {Tiers} from "./Tiers";
import {Chauffeur} from '@/models/Chauffeur';
import {Plateforme} from "@/models/Plateforme";
import {PlateformeCase} from './PlateformeCase';

@XHTTP(CubagePlateformeService, '/cubage-plateformes')
export class CubagePlateforme {

	private _id: number;
	private _dateDelivery: Date = new Date();
	private _plateforme: Plateforme;
	private _case: PlateformeCase;
	private _boisType: BoisType;
	private _chantier: Chantier;
	private _boisFini: boolean;
	private _boisQuality: BoisQuality;
	private _boisSize: BoisSize;
	private _marquage: Marquage;
	private _volumeInner: number;
	private _volumeOutter: number;
	private _coef: number;
	private _volumeStere: number;
	private _coefStere: number;
	private _remainingVolume: number;
	private _sansMarquage: boolean = false; // variable de gestion uniquement dans le front
	private _chauffeur: Chauffeur;

	/////////////
	// Getters //
	/////////////

	public get id(): number {
		return this._id;
	}

	public get dateDelivery(): Date {
		return this._dateDelivery;
	}

	public get plateforme(): Plateforme {
		return this._plateforme;
	}

	public get case(): PlateformeCase {
		return this._case;
	}

	public get chantier(): Chantier {
		return this._chantier;
	}

	public get boisFini(): boolean {
		return this._boisFini;
	}

	public get boisType(): BoisType {
		return this._boisType;
	}

	public get boisQuality(): BoisQuality {
		return this._boisQuality;
	}

	public get boisSize(): BoisSize {
		return this._boisSize;
	}

	public get marquage(): Marquage {
		return this._marquage;
	}

	public get volumeInner(): number {
		return this._volumeInner;
	}

	public get volumeOutter(): number {
		return this._volumeOutter;
	}

	public get coef(): number {
		return this._coef;
	}

	public get volumeStere(): number {
		return this._volumeStere;
	}

	public get coefStere(): number {
		return this._coefStere;
	}

	public get remainingVolume(): number {
		return this._remainingVolume;
	}

	public get sansMarquage(): boolean {
		return this._sansMarquage;
	}

	public get chauffeur(): Chauffeur {
		return this._chauffeur;
	}

	/////////
	// Add //
	/////////
	
	
	/////////////
	// Setters //
	/////////////

	public set dateDelivery(value: Date) {
		this._dateDelivery = value;
	}

	public set plateforme(value: Plateforme) {
		this._plateforme = value;
	}

	public set case(value: PlateformeCase) {
		this._case = value;
	}

	public set chantier(value: Chantier) {
		this._chantier = value;
	}

	public set boisFini(value: boolean) {
		this._boisFini = value;
	}

	public set boisType(value: BoisType) {
		this._boisType = value;
	}

	public set boisQuality(value: BoisQuality) {
		this._boisQuality = value;
	}

	public set boisSize(value: BoisSize) {
		this._boisSize = value;
	}

	public set marquage(value: Marquage) {
		this._marquage = value;
	}

	public set volumeInner(value: number) {
		this._volumeInner = value;
	}

	public set volumeOutter(value: number) {
		this._volumeOutter = value;
	}

	public set coef(value: number) {
		this._coef = value;
	}

	public set volumeStere(value: number) {
		this._volumeStere = value;
	}

	public set coefStere(value: number) {
		this._coefStere = value;
	}

	public set remainingVolume(value: number) {
		this._remainingVolume = value;
	}

	public set sansMarquage(value: boolean) {
		this._sansMarquage = value;
	}

	public set chauffeur(value: Chauffeur) {
		this._chauffeur = value;
	}

	///////////////
	// Serialize //
	///////////////

	public toJSON(): any {
		return {
			id            : this._id,
			dateDelivery    : this._dateDelivery ? DateHelper.toLocalizedString(this._dateDelivery) : null,
			plateforme    : this._plateforme ? this._plateforme.id : null,
			case    : this._case ? this._case.id : null,
			chantier    : this._chantier ? this._chantier.id : null,
			boisFini      : this._boisFini,
			boisType    : this._boisType ? this._boisType.id : null,
			boisQuality : this._boisQuality ? this._boisQuality.id : null,
			boisSize    : this._boisSize ? this._boisSize.id : null,
			marquage    : this._marquage ? this._marquage : null,
			volumeInner : this._volumeInner,
			volumeOutter: this._volumeOutter,
			coef        : this._coef,
			volumeStere: this._volumeStere,
			coefStere        : this._coefStere,
			remainingVolume        : this._remainingVolume,
			chauffeur  : this._chauffeur ? this._chauffeur.id : null,
		};
	}

	public fromJSON(data: any): this {
		this._id           = data.id
		this._dateDelivery         = data.dateDelivery ? DateHelper.fromLocalizedString(data.dateDelivery) : null;
		this._plateforme     = data.plateforme ? (new Plateforme()).fromJSON(data.plateforme) : null;
		this._case     = data.case ? (new PlateformeCase()).fromJSON(data.case) : null;
		this._chantier     = data.chantier ? (new Chantier()).fromJSON(data.chantier) : null;
		this._boisFini     = data.boisFini;
		this._boisType     = data.boisType ? (new BoisType()).fromJSON(data.boisType) : null;
		this._boisQuality  = data.boisQuality ? (new BoisQuality()).fromJSON(data.boisQuality) : null;
		this._boisSize     = data.boisSize ? (new BoisSize()).fromJSON(data.boisSize) : null;
		this._marquage     = data.marquage ? (new Marquage()).fromJSON(data.marquage) : null;
		this._volumeInner  = data.volumeInner;
		this._volumeOutter = data.volumeOutter;
		this._coef         = data.coef;
		this._volumeStere = data.volumeStere;
		this._coefStere         = data.coefStere;
		this._remainingVolume         = data.remainingVolume;
		this._chauffeur    = data.chauffeur ? (new Chauffeur()).fromJSON(data.chauffeur) : null;
		return this;
	}
}
