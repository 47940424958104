import {Chauffeur} from '@/models';

export default [
	{
		path: '/chauffeur',
		name: 'chauffeur',
		meta: {
			title: 'Liste des chauffeurs',
			granted: [ 'API_GET_CHAUFFEUR' ]
		},
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/Chauffeur.vue')
	},
	{
		path: '/chauffeur/show/:id',
		name: 'chauffeur-show',
		props: {
			slotData: () => ({
				component: import(/* webpackChunkName: "dashboard" */ '../components/Crud/Chauffeur/Show.vue'),
				class: Chauffeur
			}),
			editRoute: { name: 'chauffeur-edit' },
		},
		meta: {
			title: 'Détails d\'un chauffeur',
			granted: [ 'API_GET_CHAUFFEUR' ]
		},
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/EditShow.vue')
	},
	{
		path: '/chauffeur/edit/:id',
		name: 'chauffeur-edit',
		props: {
			slotData: () => ({
				component: import(/* webpackChunkName: "dashboard" */ '../components/Crud/Chauffeur/Edit.vue'),
				class: Chauffeur
			}),
		},
		meta: {
			title: 'Edition d\'un chauffeur',
			granted: [ 'API_PUT_CHAUFFEUR' ]
		},
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/EditShow.vue')
	},
];
