




























import {Prop} from 'vue-property-decorator';
import {Component, Vue} from '@/shared/component';
import {NumberFilter} from '@/shared/filter';

@Component()
export default class InputNumberFilter extends Vue {
	@Prop({type: NumberFilter, required: true }) private value : NumberFilter;
	@Prop ({type: Boolean  , default: false   }) private clearable!: boolean;
	@Prop ({type: Boolean  , default: false   }) private dense!: boolean;
	@Prop ({type: Number   , default: 1       }) private step: number;

	private min: number = 0;
	private max: number = 0;
	
	public mounted(): void {
		this.min = this.value.min;
		this.max = this.value.max;
	}
	
	public onChange(): void {
		if (
			this.min !== this.value.min ||
			this.max !== this.value.max
		) {
			this.min = this.value.min;
			this.max = this.value.max;
			this.$emit('change', this.value);
		}
	}
}
