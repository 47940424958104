import {Purchase} from '@/models';

export default [
	{
		path: '/purchase',
		name: 'purchase',
		meta: {
			title: 'Liste des factures',
			granted: [ 'API_GET_PURCHASE' ]
		},
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/Purchase.vue')
	},
	{
		path: '/purchase/show/:id',
		name: 'purchase-show',
		props: {
			slotData: () => ({
				component: import(/* webpackChunkName: "dashboard" */ '../components/Crud/Purchase/Show.vue'),
				class: Purchase
			}),
			editRoute: { name: 'purchase-edit' },
		},
		meta: {
			title: 'Détails d\'une factures',
			granted: [ 'API_GET_PURCHASE' ],
			editCallback: item =>  item && !item.end
		},
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/EditShow.vue')
	},
	{
		path: '/purchase/edit/:id',
		name: 'purchase-edit',
		props: {
			slotData: () => ({
				component: import(/* webpackChunkName: "dashboard" */ '../components/Crud/Purchase/Edit.vue'),
				class: Purchase
			}),
		},
		meta: {
			title: 'Edition d\'une factures',
			granted: [ 'API_PUT_PURCHASE' ]
		},
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/EditShow.vue')
	},
];
