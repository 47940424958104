







































import {Emit, Prop} from "vue-property-decorator";
import {InOut} from 'vue-inout-prop-decorator';
import {Component, Vue} from '@/shared/component';

import {Purchase} from '@/models';

import CrudPurchaseEdit from '@/components/Crud/Purchase/Edit.vue';

@Component({
	components: {
		CrudPurchaseEdit,
	}
})
export default class CrudPurchaseEditDialog extends Vue {
	@InOut({ type: Purchase  , isVModel: true }) private value!: Purchase;
	@Prop({ type: Boolean, default: false}) showOnClose: boolean;

	@Emit()
	private onSubmit(purchase: Purchase) {
		// Ouvre à la fermeture
		if (this.showOnClose) {
			this.$router.push({name: 'purchase-show', params: { id : this.value.id.toString() }});
		}
		this.onClose();
	}

	@Emit()
	private onClose() {
		if (
			this.value.id && 
			this.$route.name !== 'purchase-show' && 
			this.$route.params["id"] !== this.value.id.toString()
		) {
			this.$router.push({name: 'purchase-show', params: { id : this.value.id.toString() }});
		}
		this.value = null;
	}
}
