




























































import {Component} from '@/shared/component';
import {AbstractEditVue} from '@/components/Abstract/AbstractEditVue';
import {Purchase} from '@/models';

import storePurchase from '@/stores/modules/purchase';

import InputDatePicker from '@/components/Input/DatePicker.vue';
import CrudTiersSearch from '@/components/Crud/Tiers/Search.vue';

@Component({
	components: {
		InputDatePicker,
		CrudTiersSearch,
	}
})
export default class CrudPurchaseEdit extends AbstractEditVue<Purchase> {
	public constructor() {
		super(
			storePurchase,
			'Purchase modifié',
			'Purchase créé',
		);
	}
}
