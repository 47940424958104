import {Abatteur} from '@/models';

export default [
	{
		path: '/abatteur',
		name: 'abatteur',
		meta: {
			title: 'Liste des abatteurs',
			granted: [ 'API_GET_ABATTEUR' ]
		},
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/Abatteur.vue')
	},
	{
		path: '/abatteur/show/:id',
		name: 'abatteur-show',
		props: {
			slotData: () => ({
				component: import(/* webpackChunkName: "dashboard" */ '../components/Crud/Abatteur/Show.vue'),
				class: Abatteur
			}),
			editRoute: { name: 'abatteur-edit' },
		},
		meta: {
			title: 'Détails d\'un abatteur',
			granted: [ 'API_GET_ABATTEUR' ]
		},
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/EditShow.vue')
	},
	{
		path: '/abatteur/edit/:id',
		name: 'abatteur-edit',
		props: {
			slotData: () => ({
				component: import(/* webpackChunkName: "dashboard" */ '../components/Crud/Abatteur/Edit.vue'),
				class: Abatteur
			}),
		},
		meta: {
			title: 'Edition d\'un abatteur',
			granted: [ 'API_PUT_ABATTEUR' ]
		},
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/EditShow.vue')
	},
];
