import {DateHelper} from "@/shared/utils";

export class Deposit {
	
	private _id: number;
	private _dtDepot: Date = new Date();
	private _value: number;
	
	/////////////
	// Getters //
	/////////////
	
	public get id(): number {
		return this._id;
	}

	public get dtDepot(): Date {
		return this._dtDepot;
	}

	public get value(): number {
		return this._value;
	}

	/////////////
	// Setters //
	/////////////
	
	public set dtDepot(value: Date) {
		this._dtDepot = value;
	}
	
	public set value(value: number) {
		this._value = value;
	}

	///////////////
	// Serialize //
	///////////////

	public toJSON(): any {
		return {
			id     : this._id,
			dtDepot: this._dtDepot ? DateHelper.toLocalizedString(this._dtDepot) : null,
			value  : this._value,
		};
	}

	public fromJSON(data: any): this {
		this._id      = data.id;
		this._dtDepot = data.dtDepot ? DateHelper.fromLocalizedString(data.dtDepot) : null;
		this._value = data.value;
		return this;
	}
}