import {Marquage} from '@/models';

export default [
	{
		path: '/marquage',
		name: 'marquage',
		meta: {
			title: 'Liste des marquages',
			granted: [ 'API_GET_MARQUAGE' ]
		},
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/Marquage.vue')
	},
	{
		path: '/marquage/edit/:id',
		name: 'marquage-edit',
		props: {
			slotData: () => ({
				component: import(/* webpackChunkName: "dashboard" */ '../components/Crud/Marquage/Edit.vue'),
				class: Marquage
			}),
		},
		meta: {
			title: 'Edition d\'un marquage',
			granted: [ 'API_PUT_MARQUAGE' ]
		},
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/EditShow.vue')
	},
];