import {Chantier, Plateforme} from '@/models';

export default [
	{
		path: '/plateforme',
		name: 'plateforme',
		meta: {
			title: 'Liste des plateformes',
			granted: [ 'API_GET_PLATEFORME' ]
		},
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/Plateforme.vue')
	},
	{
		path: '/plateforme/show/:id',
		name: 'plateforme-show',
		props: {
			slotData: () => ({
				component: import(/* webpackChunkName: "dashboard" */ '../components/Crud/Plateforme/Show.vue'),
				class: Plateforme
			}),
		},
		meta: {
			title: 'Détails d\'une plateforme',
			granted: [ 'API_GET_PLATEFORME' ]
		},
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/EditShow.vue')
	},
	{
		path: '/plateforme/edit/:id',
		name: 'plateforme-edit',
		props: {
			slotData: () => ({
				component: import(/* webpackChunkName: "dashboard" */ '../components/Crud/Plateforme/Edit.vue'),
				class: Plateforme
			}),
		},
		meta: {
			title: 'Edition d\'une plateforme',
			granted: [ 'API_PUT_PLATEFORME' ]
		},
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/EditShow.vue')
	},
];
