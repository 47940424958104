import {PrestationType} from '@/models';

export default [
	{
		path: '/prestation-type',
		name: 'prestationType',
		meta: {
			title: 'Liste des types de prestation',
			granted: [ 'API_GET_PRESTATIONTYPE' ]
		},
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/PrestationType.vue')
	},
	{
		path: '/prestationType/edit/:id',
		name: 'prestationType-edit',
		props: {
			slotData: () => ({
				component: import(/* webpackChunkName: "dashboard" */ '../components/Crud/PrestationType/Edit.vue'),
				class: PrestationType
			}),
		},
		meta: {
			title: 'Edition d\'un type de prestation',
			granted: [ 'API_PUT_PRESTATIONTYPE' ]
		},
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/EditShow.vue')
	},
];