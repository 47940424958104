import {GenericService} from '@/shared/xhttp';
import {Prestation} from '@/models';

export class PrestationService extends GenericService<Prestation> {

	public async putMarkPaid(presation: Prestation) {
		await this.put(`/${presation.id}/mark-paid`);
	}

	public putMarkUnpaid(presation: Prestation) {
		this.put(`/${presation.id}/mark-unpaid`);
	}

	public putMarkPayPrepared(presation: Prestation) {
		this.put(`/${presation.id}/mark-PayPrepared`);
	}
	

	public putMarkNotPayPrepared(presation: Prestation) {
		this.put(`/${presation.id}/mark-NotPayPrepared`);
	}
	

}
