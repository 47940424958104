import Vue from 'vue';
import Vuex from 'vuex';
import {AscDesc, ResultType, XHTTPService} from '@/shared/xhttp';
import {
	PaginatorFilterState,
	PaginatorActionWithFilters,
	PaginatorMutation,
	StandardAction,
} from '@/shared/utils';
import {Tiers, TiersType, User} from '@/models';
import {TiersService} from '@/xhttp';

import {DateFilter} from '@/shared/filter';
import store from '@/stores';

Vue.use(Vuex);

export class TiersFilter {

	type: TiersType[] = [];
	name: string = '';
	company: string = '';
	remarques: string = '';

	public clear(): void {
		this.name = '';
		this.company = '';
		this.remarques = '';
		this.type = [ TiersType.PROPRIETAIRE ];
	}

	public toString(): string {
		return JSON.stringify(this);
	}
}

class TiersState extends PaginatorFilterState<TiersFilter> {
	tiers: Tiers = null;
	tierses: ResultType<Tiers> = null;
	public constructor() {
		super(new TiersFilter(), 'tierses', 'tiers', 'id', AscDesc.DESC, 20)
	}
}

class TiersStore {

	@XHTTPService(() => Tiers)
	private tiersService: TiersService;

	public state: TiersState = new TiersState();

	public mutations = {
		...PaginatorMutation(() => storeTiers, 'tierses', 'tiers'),
	};

	public actions = {
		...StandardAction<Tiers, TiersState>(() => Tiers, 'tiers'),
		...PaginatorActionWithFilters<Tiers, TiersState>(() => Tiers, 'tierses', 'tiers'),
	};

}

const instance = new TiersStore();
const storeTiers = new Vuex.Store(instance);
store.registerModule('tiers', storeTiers);
export default storeTiers;
