import {XHTTP} from '@/shared/xhttp';
import {CubageChantierService} from '@/xhttp';
import {Chantier} from './Chantier';
import {BoisType} from "@/models/BoisType";
import {BoisQuality} from "@/models/BoisQuality";
import {BoisSize} from "@/models/BoisSize";
import {Marquage} from "@/models/Marquage";
import {Tiers} from "./Tiers";
import {DocumentCubage} from "./DocumentCubage";
import {Chauffeur} from '@/models/Chauffeur';
import { DocumentCubagePourChauffeur } from './DocumentCubagePourChauffeur';

@XHTTP(CubageChantierService, '/cubage-chantiers')
export class CubageChantier {
	
	private _id: number;
	private _chantier: Chantier;
	private _scierie: Tiers;
	private _creerParLivraison: boolean;
	private _numero: string;
	private _boisType: BoisType;
	private _boisQuality: BoisQuality;
	private _boisSize: BoisSize;
	private _marquage: Marquage;
	private _volumeInner: number;
	private _volumeFromLivraison: boolean;
	private _volumeOutter: number;
	private _coef: number;
	private _volumeStere: number;
	private _coefStere: number;
	private _documents: DocumentCubage[] = [];
	private _documentsPourChauffeur: DocumentCubagePourChauffeur[] = [];
	private _coutM3: number;
	private _chauffeurParDefaut: Chauffeur;
	private _messageChauffeur: string;
	private _tousChauffeur: boolean;
	private _resteADebarder: boolean;
	private _volumeInnerAvantDebardage: number;
	private _boisVendu: boolean;

	/////////////
	// Getters //
	/////////////

	public get id(): number {
		return this._id;
	}

	public get creerParLivraison(): boolean {
		return this._creerParLivraison;
	}

	public get chantier(): Chantier {
		return this._chantier;
	}

	public get numero(): string {
		return this._numero;
	}

	public get scierie(): Tiers {
		return this._scierie;
	}

	public get boisType(): BoisType {
		return this._boisType;
	}

	public get boisQuality(): BoisQuality {
		return this._boisQuality;
	}

	public get boisSize(): BoisSize {
		return this._boisSize;
	}

	public get marquage(): Marquage {
		return this._marquage;
	}

	public get volumeInner(): number {
		return this._volumeInner;
	}
	
	public get volumeFromLivraison(): boolean {
		return this._volumeFromLivraison;
	}

	public get volumeOutter(): number {
		return this._volumeOutter;
	}

	public get coef(): number {
		return this._coef;
	}

	public get volumeStere(): number {
		return this._volumeStere;
	}

	public get coefStere(): number {
		return this._coefStere;
	}

	public get documents(): DocumentCubage[] {
		return this._documents;
	}

	public get documentsPourChauffeur(): DocumentCubagePourChauffeur[] {
		return this._documentsPourChauffeur;
	}

	public get coutM3(): number {
		return this._coutM3;
	}

	public get chauffeurParDefaut(): Chauffeur {
		return this._chauffeurParDefaut;
	}

	public get messageChauffeur(): string {
		return this._messageChauffeur;
	}

	public get tousChauffeur(): boolean {
		return this._tousChauffeur;
	}

	public get resteADebarder(): boolean {
		return this._resteADebarder;
	}

	public get volumeInnerAvantDebardage(): number {
		return this._volumeInnerAvantDebardage;
	}
	
	public get boisVendu(): boolean {
		return this._boisVendu;
	}

	/////////////
	// Setters //
	/////////////

	public set id(value: number) {
		this._id = value;
	}

	public set creerParLivraison(value: boolean) {
		this._creerParLivraison = value;
	}

	public set chantier(value: Chantier) {
		this._chantier = value;
	}

	public set scierie(value: Tiers) {
		this._scierie = value;
	}
	
	public set numero(value: string) {
		this._numero = value;
	}

	public set boisType(value: BoisType) {
		this._boisType = value;
	}

	public set boisQuality(value: BoisQuality) {
		this._boisQuality = value;
	}

	public set boisSize(value: BoisSize) {
		this._boisSize = value;
	}

	public set marquage(value: Marquage) {
		this._marquage = value;
	}

	public set volumeInner(value: number) {
		this._volumeInner = value;
	}
	
	public set volumeFromLivraison(value: boolean) {
		this._volumeFromLivraison = value;
	}
	
	public set volumeOutter(value: number) {
		this._volumeOutter = value;
	}

	public set coef(value: number) {
		this._coef = value;
	}

	public set volumeStere(value: number) {
		this._volumeStere = value;
	}

	public set coefStere(value: number) {
		this._coefStere = value;
	}

	public set coutM3(value: number) {
		this._coutM3 = value;
	}

	public set chauffeurParDefaut(value: Chauffeur) {
		this._chauffeurParDefaut = value;
	}

	public set messageChauffeur(value: string) {
		this._messageChauffeur = value;
	}

	public set tousChauffeur(value: boolean) {
		this._tousChauffeur = value;
	}

	public set resteADebarder(value: boolean) {
		this._resteADebarder = value;
	}

	public set volumeInnerAvantDebardage(value: number) {
		this._volumeInnerAvantDebardage = value;
	}
	
	public set boisVendu(value: boolean) {
		this._boisVendu = value;
	}

	/////////
	// Add //
	/////////

	public addDocuments(document: DocumentCubage): this {
		document.cubage = this;
		if (this._documents.indexOf(document) === -1) {
			this._documents.push(document);
		}
		return this;
	}

	public removeDocuments(document: DocumentCubage): this {
		document.cubage = this;
		const index = this._documents.indexOf(document);
		if (index !== -1) {
			this._documents.splice(index, 1);
		}
		return this;
	}
	
	public addDocumentsPourChauffeur(document: DocumentCubagePourChauffeur): this {
		document.cubage = this;
		if (this._documentsPourChauffeur.indexOf(document) === -1) {
			this._documentsPourChauffeur.push(document);
		}
		return this;
	}

	public removeDocumentsPourChauffeur(document: DocumentCubagePourChauffeur): this {
		document.cubage = this;
		const index = this._documentsPourChauffeur.indexOf(document);
		if (index !== -1) {
			this._documentsPourChauffeur.splice(index, 1);
		}
		return this;
	}
	
	///////////////
	// Serialize //
	///////////////

	public toJSON(): any {
		return {
			id          : this._id,
			chantier    : this._chantier ? this._chantier.id : null,
			scierie         : this._scierie ? this._scierie.id : null,
			creerParLivraison      : this._creerParLivraison,
			numero      : this._numero,
			boisType    : this._boisType ? this._boisType.id : null,
			boisQuality : this._boisQuality ? this._boisQuality.id : null,
			boisSize    : this._boisSize ? this._boisSize.id : null,
			marquage    : this._marquage ? this._marquage : null,
			volumeInner : this._volumeInner,
			volumeFromLivraison: this._volumeFromLivraison,
			volumeOutter: this._volumeOutter,
			coef        : this._coef,
			volumeStere: this._volumeStere,
			coefStere        : this._coefStere,
			coutM3  : this._coutM3,
			chauffeurParDefaut  : this._chauffeurParDefaut ? this._chauffeurParDefaut.id : null,
			messageChauffeur  : this._messageChauffeur,
			tousChauffeur  : this._tousChauffeur,
			resteADebarder  : this._resteADebarder,
			volumeInnerAvantDebardage  : this._volumeInnerAvantDebardage,
			boisVendu  : this._boisVendu,
		};
	}
	
	public fromJSON(data: any): this {
		this._id           = data.id;
		this._chantier     = data.chantier ? (new Chantier()).fromJSON(data.chantier) : null;
		this._scierie      = data.scierie ? (new Tiers).fromJSON(data.scierie) : null;
		this._creerParLivraison       = data.creerParLivraison;
		this._numero       = data.numero;
		this._boisType     = data.boisType ? (new BoisType()).fromJSON(data.boisType) : null;
		this._boisQuality  = data.boisQuality ? (new BoisQuality()).fromJSON(data.boisQuality) : null;
		this._boisSize     = data.boisSize ? (new BoisSize()).fromJSON(data.boisSize) : null;
		this._marquage     = data.marquage ? (new Marquage()).fromJSON(data.marquage) : null;
		this._volumeInner  = data.volumeInner;
		this._volumeFromLivraison  = data.volumeFromLivraison;
		this._volumeOutter = data.volumeOutter;
		this._coef         = data.coef;
		this._volumeStere = data.volumeStere;
		this._coefStere         = data.coefStere;
		if (data.documentCubages)  {
			for (const d of data.documentCubages) {
				this.addDocuments((new DocumentCubage()).fromJSON(d));
			}
		}
		if (data.documentCubagePourChauffeurs)  {
			for (const d of data.documentCubagePourChauffeurs) {
				this.addDocumentsPourChauffeur((new DocumentCubagePourChauffeur()).fromJSON(d));
			}
		}
		this._coutM3    = data.coutM3;
		this._chauffeurParDefaut    = data.chauffeurParDefaut ? (new Chauffeur()).fromJSON(data.chauffeurParDefaut) : null;
		this._messageChauffeur    = data.messageChauffeur;
		this._tousChauffeur    = data.tousChauffeur;
		this._resteADebarder = data.resteADebarder;
		this._volumeInnerAvantDebardage = data.volumeInnerAvantDebardage;
		this._boisVendu = data.boisVendu;

		return this;
	}
}
