


















import {Stored} from "vue-stored-prop-decorator";
import {Prop} from "vue-property-decorator";
import {Component} from '@/shared/component';
import {AbstractSearchVue} from '@/components/Abstract/AbstractSearchVue';
import {TiersType} from "@/models";

import InputSearchEntity from '@/components/Input/SearchEntity.vue';
import CrudTiersFilterReduce from '@/components/Crud/Tiers/FilterReduce.vue';
import CrudTiersListForSelect from '@/components/Crud/Tiers/ListForSelect.vue';
import storeTiers, {TiersFilter} from "@/stores/modules/tiers";

@Component({
	components: {
		CrudTiersFilterReduce,
		CrudTiersListForSelect,
		InputSearchEntity,
	}
})
export default class CrudTiersSearch extends AbstractSearchVue {
	@Prop({ type: Array, default: () => [] }) types: TiersType[];
	//private type: TiersType = null;

	@Stored(() => storeTiers) filters: TiersFilter;

	private filterComponent = CrudTiersFilterReduce;
	private listComponent = CrudTiersListForSelect;

	private async setFilters(): Promise<void> {
		await this.$nextTick();
		this.filters.type = this.types;
	}
}
