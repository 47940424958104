




























































































































import Time from 'gollumts-time';
import {InOut} from 'vue-inout-prop-decorator';
import {Prop, Ref, Watch} from "vue-property-decorator";
import {Store} from "vuex";
import {Component, Vue} from '@/shared/component';
import {PaginatorState} from "@/shared/utils";

import InlineMenu from '@/components/InlineMenu.vue';

@Component()
export default class InputSearchEntity extends Vue {

	@InOut({type: [ Object, Array ], isVModel: true }) public value!     : any;
	@Prop ({type: Boolean, default: false           }) public multiple!  : boolean;
	@Prop({ type: Boolean, default: false           }) public disabled!: boolean;
	@Prop({ type: Boolean, default: false           }) public inline!  : boolean;
	@Prop({ type: Store,   default: null            }) public store!: Store<PaginatorState>;

	@Prop ({type: Function, default: null           }) public filterComponent!: Vue;
	@Prop ({type: Function, default: null           }) public listComponent!: Vue;
	@InOut({type: Object  , default: () => ({})     }) public attrsList!: any;
	@InOut({type: Object  , default: () => ({})     }) public attrsFilter!: any;

	@Ref() private elFilter!: HTMLElement;
	@Ref() private elFakeInput!: HTMLElement;

	private open: boolean = false;
	private textValue: string = ' ';
	private InlineMenu = InlineMenu;;

	@Watch('textValue')
	private async clear(newValue: string = null): Promise<void> {
		if(newValue && newValue !== ' ') {
			this.onOpen();
		}
		await this.$nextTick();
		this.textValue = ' ';
		this.onOpen(newValue);
	}

	private findFirstInput(): HTMLInputElement {
		if (!this.elFilter) {
			return null;
		}
		return this.elFilter.querySelector<HTMLInputElement>(
			'input[type="text"],' +
			'input[type="email"]'
		);
	}

	private async onKeyDown(e: KeyboardEvent): Promise<void> {
		if (e.key === 'Backspace') {
			if (this.multiple) {
				if (Array.isArray(this.value)) {
					this.value.pop();
				}
			} else {
				this.value = null;
			}
		}
	}

	private async onOpen(newValue: string = ''): Promise<void> {
		this.$emit('open');
		if (this.disabled) {
			return;
		}
		this.open = true;
		await this.$nextTick();

		let input = this.findFirstInput();
		if (!input) {
			if (typeof newValue === 'string') {
				input.value += newValue.trim();
			}
			input.focus();
		} else {
			await Time.timeout(400);
			input = this.findFirstInput();
			if (input) {
				if (typeof newValue === 'string') {
					input.value += newValue.trim();
				}
				input.focus();
			}
		}
	}

	private onClose(): void {
		this.open = false;
	}

	private isSelected(object: any): boolean {
		if (this.multiple) {
			return Array.isArray(this.value) &&
				!!this.value.filter(o => o.id === object.id).length
			;
		}
		return this.value && this.value.id === object.id;
	}

	private onSelect(object: any): void {
		if (this.multiple) {
			if (Array.isArray(this.value)) {
				if (!this.value.filter(o => o.id === object.id).length) {
					this.value = this.value.concat([ object ]);
				}
			} else {

				const value = [ object ];
				this.value = value;
				this.$emit('change', value);
			}
		} else {
			this.value = object;
			this.open = false;
			this.$emit('change', object);
		}
	}

	private onUnSelect(object: any): void {
		if (this.multiple) {
			if (Array.isArray(this.value)) {
				this.value = this.value.filter(o => o.id !== object.id);
			}
		} else {
			this.value = null;
		}
	}

	private onFocus(): void {
		this.elFakeInput.focus();
	}

	private onClear(): void {
		if (this.disabled) {
			return;
		}
		if (this.multiple) {
			const value = [];
			this.value = [];
			this.$emit('change', value);
		} else {
			this.value = null;
			this.$emit('change', null);
		}
		this.onFocus();
	}

}
