









import {Component, Vue} from '@/shared/component';
import {Prop} from "vue-property-decorator";
import {PrestationType} from "@/models";

import storePrestationType from '@/stores/modules/prestationType';

import InputGenericEntitySelect from './GenericEntitySelect.vue';

@Component({
	components: {
		InputGenericEntitySelect
	}
})
export default class InputPrestationType extends Vue {
	store = storePrestationType;
	@Prop({ type: Boolean, default: true }) displaySale!: boolean;
	@Prop({ type: Boolean, default: true }) displayBuy!: boolean;
	
	private itemFilter(value: PrestationType) {
		if (this.displaySale && value.sale) {
			return true;
		}
		if (this.displayBuy && !value.sale) {
			return true;
		}
		return false;
	}
}
