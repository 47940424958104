














































import {Prop} from "vue-property-decorator";
import {InOut} from 'vue-inout-prop-decorator';
import {Component, Vue} from '@/shared/component';
import {Form} from '@/shared/form';

import {Address} from '@/models';
import InputCountry from '@/components/Input/Country.vue';
import InputZipCode from '@/components/Input/ZipCode.vue';

@Component({
	components: {
		InputCountry,
		InputZipCode,
	}
})
export default class Edit extends Vue {
	@InOut({type: Address, isVModel: true}) private value!    : Address;
	@Prop ({type: Form   , default: null }) private form!     : Form;
	@Prop ({type: String , default: ''   }) private name!     : string;
	
	public get prefix(): string {
		return this.name ? this.name+'.' : '';
	}
	
	public mounted(): void {
		if (!this.value) {
			this.value = new Address();
		}
	}
}
