import {XHTTP} from '@/shared/xhttp';
import {TiersService} from '@/xhttp';
import {Address} from './Address';
import {Contact} from '@/models/Contact';
import {Contrat} from '@/models/Contrat';
import {TiersM3Price} from "@/models/TiersM3Price";

export type TiersType = 'PROPRIETAIRE'|'SCIERIE';
export const TiersType = {
	PROPRIETAIRE: 'PROPRIETAIRE' as TiersType,
	SCIERIE: 'SCIERIE' as TiersType,
};

@XHTTP(TiersService, '/tierses')
export class Tiers {

	private _id: number;
	private _type: TiersType;
	private _name: string;
	private _company: string;
	private _remarques: string;
	private _phone1: string;
	private _phone2: string;
	private _purchaseAddressUseAddress: boolean = true;
	private _address: Address = new Address();
	private _purchaseAddress: Address = new Address();
	private _createdAt: Date;
	private _prixM3Defaut: number;
	private _contacts: Contact[];
	private _contrats: Contrat[];
	private _tiersM3Prices: TiersM3Price[] = [];

	/////////////
	// Getters //
	/////////////

	public get id(): number {
		return this._id;
	}

	public get type(): TiersType {
		return this._type;
	}

	public get name(): string {
		return this._name;
	}

	public get company(): string {
		return this._company;
	}

	public get remarques(): string {
		return this._remarques;
	}

	public get phone1(): string {
		return this._phone1;
	}

	public get phone2(): string {
		return this._phone2;
	}

	public get purchaseAddressUseAddress(): boolean {
		return this._purchaseAddressUseAddress;
	}

	public get address(): Address {
		return this._address;
	}

	public get purchaseAddress(): Address {
		return this._purchaseAddress;
	}

	public get contacts(): Contact[] {
		return this._contacts;
	}

	public get contrats(): Contrat[] {
		return this._contrats;
	}

	public get createdAt(): Date {
		return this._createdAt;
	}

	public get prixM3Defaut(): number {
		return this._prixM3Defaut;
	}

	public get tiersM3Prices(): TiersM3Price[] {
		return this._tiersM3Prices;
	}

	/////////////
	// Setters //
	/////////////

	public set type(value: TiersType) {
		this._type = value;
	}

	public set name(value: string) {
		this._name = value;
	}

	public set company(value: string) {
		this._company = value;
	}

	public set remarques(value: string) {
		this._remarques = value;
	}

	public set phone1(value: string) {
		this._phone1 = value;
	}

	public set phone2(value: string) {
		this._phone2 = value;
	}

	public set address(value: Address) {
		this._address = value;
	}

	public set purchaseAddressUseAddress(value: boolean) {
		this._purchaseAddressUseAddress = value;
	}

	public set purchaseAddress(value: Address) {
		this._purchaseAddress = value;
	}

	public set prixM3Defaut(value: number) {
		this._prixM3Defaut = value;
	}

	public set tiersM3Prices(value: TiersM3Price[]) {
		this._tiersM3Prices = value;
	}

	///////////////
	// Serialize //
	///////////////+

	public toJSON(): any {
		return {
			id       : this._id,
			type     : this._type,
			name     : this._name,
			company  : this._company,
			remarques  : this._remarques,
			phone1   : this._phone1,
			phone2   : this._phone2,
			address  : this._address ? this._address.toJSON() : null,
			purchaseAddressUseAddress: this._purchaseAddressUseAddress,
			purchaseAddress          : !this._purchaseAddressUseAddress && this._purchaseAddress ? this._purchaseAddress.toJSON() : null,
			prixM3Defaut       : this._prixM3Defaut,
			tiersM3Prices: this._tiersM3Prices ? this._tiersM3Prices.map(m => m.toJSON()) : [],
		};
	}

	public fromJSON(data: any): this {
		this._id        = data.id;
		this._type      = data.type;
		this._name      = data.name;
		this._company   = data.company; 
		this._remarques   = data.remarques;
		this._phone1    = data.phone1;
		this._phone2    = data.phone2;
		this._address   = data.address  ? (new Address()).fromJSON(data.address)  : new Address();
		this._purchaseAddressUseAddress  = !!data.purchaseAddressUseAddress;
		this._purchaseAddress            = data.purchaseAddress ? (new Address()).fromJSON(data.purchaseAddress) : new Address();
		this._createdAt                  = data.createdAt ? new Date(data.createdAt) : null;
		this._contacts = data.contacts ? data.contacts.map(c => (new Contact()).fromJSON(c)) : null;
		this._contrats = data.contrats ? data.contrats.map(c => (new Contrat()).fromJSON(c)) : null;
		this._prixM3Defaut        = data.prixM3Defaut;
		this._tiersM3Prices                = data.tiersM3Prices ? data.tiersM3Prices.map(m => (new TiersM3Price()).fromJSON(m)) : [];
		return this;
	}
}
