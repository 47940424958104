import {GenericService} from '@/shared/xhttp';
import {Purchase} from '@/models';

export class PurchaseService extends GenericService<Purchase> {

	patchEnd(purchase: Purchase): Promise<Purchase> {
		return this.patch(`/${purchase.id}/end`, null);
	}
	
	patchRefund(purchase: Purchase): Promise<Purchase> {
		return this.patch(`/${purchase.id}/refund`, null);
	}
	
	patchPrices(purchase: Purchase): Promise<Purchase> {
		return this.patch(`/${purchase.id}/prices`, purchase);
	}
}
