






























import {Prop, Watch} from "vue-property-decorator";
import {Stored} from 'vue-stored-prop-decorator';
import {Component, Vue} from '@/shared/component';
import {Timeout, WatchObjectProperties} from '@/shared/decorator';

import storeUser, {UserFilter} from '@/stores/modules/user';

@Component({
	components: {
	}
})
export default class CrudUserFilterReduce extends Vue {

	@Prop({ type: Boolean, default: false}) reduce!: boolean;

	@Stored(() => storeUser) private page!: number;
	@Stored(() => storeUser) private filters!: UserFilter;

	@WatchObjectProperties('filters', UserFilter)
	@Timeout()
	private filtersWatcher(): void {
		this.page = 0;
		storeUser.dispatch('getC', { filters: true });
	}
	
	public mounted() {
	}

}

