import {Chantier} from '@/models';

export default [
	{
		path: '/chantier',
		name: 'chantier',
		meta: {
			title: 'Liste des chantiers',
			granted: [ 'NAV_CONTRATS' ]
		},
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/Chantier.vue')
	},
	{
		path: '/mes-chantiers',
		name: 'mes-chantiers',
		meta: {
			title: 'Mes chantiers',
			granted: [ 'API_GET_CHANTIER' ]
		},
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/MesChantiers.vue')
	},
	{
		path: '/chantier_bilan',
		name: 'chantier_bilan',
		meta: {
			title: 'Liste des chantiers avec bilan cloturé',
			granted: [ 'API_GET_PURCHASE' ]
		},
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/Chantier_bilan.vue')
	},
	{
		path: '/chantier/show/:id',
		name: 'chantier-show',
		props: {
			slotData: () => ({
				component: import(/* webpackChunkName: "dashboard" */ '../components/Crud/Chantier/Show.vue'),
				class: Chantier
			}),
		},
		meta: {
			title: 'Détails d\'un chantier',
			granted: [ 'API_GET_CHANTIER' ]
		},
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/EditShow.vue')
	},
	{
		path: '/chantier/edit/:id',
		name: 'chantier-edit',
		props: {
			slotData: () => ({
				component: import(/* webpackChunkName: "dashboard" */ '../components/Crud/Chantier/Edit.vue'),
				class: Chantier
			}),
		},
		meta: {
			title: 'Edition d\'un chantier',
			granted: [ 'API_PUT_CHANTIER' ]
		},
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/EditShow.vue')
	},
	{
		path: '/chantier/showfiche/:id',
		name: 'chantier-fiche-show',
		props: {
			slotData: () => ({
				component: import(/* webpackChunkName: "dashboard" */ '../components/Crud/Chantier/ShowFiche.vue'),
				class: Chantier
			}),
		},
		meta: {
			title: 'Fiche chantier',
			granted: [ 'API_GET_CHANTIER' ]
		},
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/EditShow.vue')
	},
	{
		path: '/chantier-map',
		name: 'chantier-map',
		meta: {
			title: 'MAP des chantiers',
			granted: [ 'API_GET_CHANTIER' ]
		},
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/Chantier-map.vue')
	},
	{
		path: '/chantier-resteadebarder',
		name: 'chantier-resteadebarder',
		meta: {
			title: 'Reste à débarder',
			granted: [ 'API_GET_CHANTIER' ]
		},
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/ResteADebarder.vue')
	},
];