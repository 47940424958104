import {Media} from "./Media";
import {Chantier} from "@/models/Chantier";
import {DocumentChantierService} from "@/xhttp";
import {XHTTP} from "@/shared/xhttp";

@XHTTP(DocumentChantierService, '/documentchantiers')
export class DocumentChantier {

    private _id: number;
    private _media: Media;
    private _chantier: Chantier;
    private _chantier2: Chantier;
    private _chantier3: Chantier;
    private _createdAt: Date;

    /////////////
    // Getters //
    /////////////

    public get id(): number {
        return this._id;
    }

    public get media(): Media {
        return this._media;
    }

    public get chantier(): Chantier {
        return this._chantier;
    }

    public get chantier2(): Chantier {
        return this._chantier2;
    }

    public get chantier3(): Chantier {
        return this._chantier3;
    }

    public get createdAt(): Date {
        return this._createdAt;
    }

    /////////////
    // Setters //
    /////////////

    public set media(value: Media) {
        this._media = value;
    }

    public set chantier(value: Chantier) {
        this._chantier = value;
    }

    public set chantier2(value: Chantier) {
        this._chantier2 = value;
    }

    public set chantier3(value: Chantier) {
        this._chantier3 = value;
    }

    ///////////////
    // Serialize //
    ///////////////

    public fromJSON(data: any): this {
        this._id        = data.id;
        this._media     = (new Media()).fromJSON(data.media);
        this._createdAt = data.createdAt ? new Date(data.createdAt) : null;
        return this;
    }
}
