







































import {Emit, Prop} from "vue-property-decorator";
import {InOut} from 'vue-inout-prop-decorator';
import {Component, Vue} from '@/shared/component';

import {Plateforme} from '@/models';

import CrudPlateformeEdit from '@/components/Crud/Plateforme/Edit.vue';

@Component({
	components: {
		CrudPlateformeEdit,
	}
})
export default class CrudPlateformeEditDialog extends Vue {
	@InOut({ type: Plateforme  , isVModel: true }) private value!: Plateforme;
	@Prop({ type: Boolean, default: false}) showOnClose: boolean;

	@Emit()
	private onSubmit(plateforme: Plateforme) {
		// Ouvre à la fermeture
		if (this.showOnClose) {
			this.$router.push({name: 'plateforme-show', params: { id : this.value.id.toString() }});
		}
		this.onClose();
	}

	@Emit()
	private onClose() {
		if (
			this.value.id && 
			this.$route.name !== 'plateforme-show' &&
			this.$route.params["id"] !== this.value.id.toString()
		) {
			this.$router.push({name: 'plateforme-show', params: { id : this.value.id.toString() }});
		}
		this.value = null;
	}
}
