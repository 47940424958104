
















import {Stored} from "vue-stored-prop-decorator";
import {Prop} from "vue-property-decorator";
import {Component} from '@/shared/component';
import {AbstractSearchVue} from '@/components/Abstract/AbstractSearchVue';
import {TiersType} from "@/models";

import InputSearchEntity from '@/components/Input/SearchEntity.vue';
import CrudUserFilterReduce from '@/components/Crud/User/FilterReduce.vue';
import CrudUserListForSelect from '@/components/Crud/User/ListForSelect.vue';
import storeUsers from "@/stores/modules/user";

@Component({
	components: {
		CrudUserFilterReduce,
		CrudUserListForSelect,
		InputSearchEntity,
	}
})
export default class CrudTiersSearch extends AbstractSearchVue {

	private filterComponent = CrudUserFilterReduce;
	private listComponent = CrudUserListForSelect;
}
