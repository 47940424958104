export class ObjectHelper {

	public static clone<T = any>(obj: T): T {
		var cloneObj = new (<any>obj.constructor)();
		for (var attribut in obj) {
			cloneObj[attribut] = obj[attribut];
		}
		return cloneObj;
	}

	public static cloneRecursive<T = any>(obj: T): T {
		var cloneObj = new (<any>obj.constructor)();
		for (var attribut in obj) {
			if (typeof obj[attribut] === "object") {
				cloneObj[attribut] = this.cloneRecursive(obj[attribut]);
			} else {
				cloneObj[attribut] = obj[attribut];
			}
		}
		return cloneObj;
	}
}