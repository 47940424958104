import {GenericService} from '@/shared/xhttp';
import {Parcelle} from '@/models';

export class ParcelleService extends GenericService<Parcelle> {
	
	public last(): Promise<Parcelle> {
		return this.get('/last');
	}
	
}
