import Vue from 'vue';
import Vuex, { ActionContext } from 'vuex';
import {ResultType, XHTTPService} from '@/shared/xhttp';
import {
	PaginatorActionWithFilters,
	PaginatorFilterState,
	PaginatorMutation, SetterMutation,
	StandardAction
} from '@/shared/utils';
import {DateFilter} from '@/shared/filter';
import {Contrat, Plateforme, Tiers} from '@/models';
import {PlateformeService} from '@/xhttp';
import store from '@/stores';


Vue.use(Vuex);

export class PlateformeFilter {

	public numeroPlateforme: string = '';
	public numeroSection: string = '';
	public zipCode: string = '';
	public town: string = '';
	public lieuxDit: string = '';
	public createdAt: DateFilter = new DateFilter();

	public clear(): void {
		this.numeroPlateforme = '';
		this.numeroSection = '';
		this.zipCode = '';
		this.town = '';
		this.lieuxDit = '';
		this.createdAt = new DateFilter();
	}

	public toString(): string {
		return JSON.stringify({
			numeroPlateforme: this.numeroPlateforme,
			numeroSection : this.numeroSection,
			zipCode: this.zipCode,
			town : this.town,
			lieuxDit: this.lieuxDit,
			createdAt     : this.createdAt,
		});
	}
}

class PlateformeState extends PaginatorFilterState<PlateformeFilter> {
	public plateforme: Plateforme = null;
	public last: Plateforme = null;
	public plateformes: ResultType<Plateforme> = null;
	public constructor() {
		super(new PlateformeFilter(), 'plateformes', 'plateforme');
	}
}

class PlateformeStore {

	@XHTTPService(() => Plateforme)
	private plateformeService: PlateformeService;

	public state: PlateformeState = new PlateformeState();

	public mutations = {
		...PaginatorMutation(() => storePlateforme, 'plateformes', 'plateforme'),
		...SetterMutation([ 'last' ])
	};

	public actions = {
		...StandardAction<Plateforme, PlateformeState>(() => Plateforme, 'plateforme'),
		...PaginatorActionWithFilters<Plateforme, PlateformeState>(() => Plateforme, 'plateformes', 'plateforme'),
	};

}
const instance = new PlateformeStore();
const storePlateforme = new Vuex.Store(instance);
store.registerModule('plateforme', storePlateforme);
export default storePlateforme;
