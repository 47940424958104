






















































































































































	import {Emit} from "vue-property-decorator";
	import {Component} from '@/shared/component';
	import {AbstractEditVue} from '@/components/Abstract/AbstractEditVue';
  import {Chantier, Prestation} from '@/models';

	import storeChantier from '@/stores/modules/chantier';

	import InputDatePicker from '@/components/Input/DatePicker.vue';
	import CrudPrestationSearch from '@/components/Crud/Prestation/Search.vue';
  import {Watch} from 'vue-property-decorator';
  import storePrestation from '@/stores/modules/prestation';
    import CrudChauffeurSearch from '@/components/Crud/Chauffeur/Search.vue';

	@Component({
		components: {
			InputDatePicker,
			CrudPrestationSearch,
			CrudChauffeurSearch,
		}
	})
	export default class CrudChantierEdit extends AbstractEditVue<Chantier> {

		private addPrestationList: Prestation[] = [];
		private step: number = 1;

		public constructor() {
			super(
				storeChantier,
				'Chantier modifié',
				'Chantier créé',
			);
		}

		@Emit()
		private onNext() {
			this.step = 2;
      console.log(this.step);
		}

		protected async onSubmit(): Promise<void> {
			await super.onSubmit();
			this.step = 1;
		}

		public async mounted(): Promise<void> {
      await super.mounted();
      
      if (this.item && this.item.id)
      {
        this.step = 2;
      }
      console.log(this.step);
		}

		@Watch('addPrestationList')
		private prestationsWatcher(): void {
		  if (!this.addPrestationList.length) {
				this.item.name = 'CH-?????';
			}
			for (const p of this.addPrestationList) {
          if (!this.item.commune || this.item.commune == '') this.item.commune = p.commune;
          if (!this.item.GPS || this.item.GPS == '') this.item.GPS = p.GPS;
          if (!this.item.lien1 || this.item.lien1 == '') this.item.lien1 = p.localisation;
          //if (!this.item.lieudit || this.item.lieudit == '') this.item.lieudit = p.lieudit;
					if (!this.item.client || this.item.client == '') this.item.client = p.contrat.tiers.name;

				if (this.addPrestationList.length === 1) {
					this.item.name = 'CH-'+p.contrat.numero;
				} else {
					this.item.name = 'CH-'+this.addPrestationList[0].contrat.numero+'-X';
				}
			}
		}

		protected async saved(): Promise<void> {
			try {
        console.log("Saved");
				const prestations = await Promise.all(
					this.addPrestationList.map(prestation => storePrestation.dispatch('get', prestation.id))
        );
				for (const prestation of prestations) {
					prestation.chantier = this.item;
					await storePrestation.dispatch('put', prestation);
				}
        
			} catch(e) {
				console.error(e);
			}
		}
		
	}
