








































import {Emit, Prop} from "vue-property-decorator";
import {InOut} from 'vue-inout-prop-decorator';
import {Component, Vue} from '@/shared/component';

import {Chantier} from '@/models';

import CrudChantierEdit from '@/components/Crud/Chantier/Edit.vue';

@Component({
	components: {
		CrudChantierEdit,
	}
})
export default class CrudChantierEditDialog extends Vue {
	@InOut({ type: Chantier  , isVModel: true }) private value!: Chantier;
	@Prop({ type: Boolean, default: false}) showOnClose: boolean;
	@Prop({ type: Boolean, default: false}) noRefresh: boolean;

	@Emit()
	private onSubmit(chantier: Chantier) {
        //console.log("Submit dialog");
		if (this.showOnClose) {
			this.$router.push({name: 'chantier-show', params: { id : this.value.id.toString() }});
		}
		this.onClose();
	}

	@Emit()
	private onClose() {
        //console.log("Close dialog");
		if (
			this.value.id && (
				this.$route.name !== 'chantier-show' ||
				this.$route.params["id"] !== this.value.id.toString()
			)
		) {
			this.$router.push({name: 'chantier-show', params: { id : this.value.id.toString() }});
		}
		this.value = null;
	}
}
