import {XHTTP} from '@/shared/xhttp';
import {CubageChantierRestantService} from '@/xhttp';
import {BoisType} from "@/models/BoisType";
import {BoisQuality} from "@/models/BoisQuality";
import {BoisSize} from "@/models/BoisSize";
import {Chantier} from '@/models/Chantier';
import {Marquage} from "@/models/Marquage";

@XHTTP(CubageChantierRestantService, '/cubage-chantier-restants')
export class CubageChantierRestant {

	private _id: number;
	private _volume: number;
	private _boisType: BoisType;
	private _boisQuality: BoisQuality;
	private _boisSize: BoisSize;
	private _marquage: Marquage;
	private _chantier: Chantier;				

	/////////////
	// Getters //
	/////////////

	public get id(): number {
		return this._id;
	}

	public get volume(): number {
		return this._volume;
	}

	public get boisType(): BoisType {
		return this._boisType;
	}

	public get boisQuality(): BoisQuality {
		return this._boisQuality;
	}

	public get boisSize(): BoisSize {
		return this._boisSize;
	}

	public get marquage(): Marquage {
		return this._marquage;
	}

	public get chantier(): Chantier {
		return this._chantier;
	}

	/////////////
	// Setters //
	/////////////

	public set id(value: number) {
		this._id = value;
	}

	public set volume(value: number) {
		this._volume = value;
	}

	public set boisType(value: BoisType) {
		this._boisType = value;
	}

	public set boisQuality(value: BoisQuality) {
		this._boisQuality = value;
	}

	public set boisSize(value: BoisSize) {
		this._boisSize = value;
	}

	public set marquage(value: Marquage) {
		this._marquage = value;
	}

	public set chantier(value: Chantier) {
		this._chantier = value;
	}

	///////////////
	// Serialize //
	///////////////

	public toJSON(): any {
		return {
			id         : this._id,
			volume     : this._volume,
			boisType   : this._boisType ? this._boisType.id : null,
			boisQuality: this._boisQuality ? this._boisQuality.id : null,
			boisSize   : this._boisSize ? this._boisSize.id : null,
			marquage   : this._marquage ? this._marquage.id : null,
			chantier   : this._chantier ? this._chantier.id : null,
		};
	}

	public fromJSON(data: any): this {
		this._id          = data.id;
		this._volume      = data.volume;
		this._boisType    = data.boisType ? (new BoisType()).fromJSON(data.boisType) : null;
		this._boisQuality = data.boisQuality ? (new BoisQuality()).fromJSON(data.boisQuality) : null;
		this._boisSize    = data.boisSize ? (new BoisSize()).fromJSON(data.boisSize) : null;
		this._marquage    = data.marquage ? (new Marquage()).fromJSON(data.marquage) : null;
		this._chantier  = data.chantier ? (new Chantier()).fromJSON(data.chantier) : null;
		return this;
	}
}
