import {GenericService} from '@/shared/xhttp';
import {CubagePrestationEstimed, Contrat, Chantier, Prestation, CubageChantier, CubageLivraison, Upload} from '@/models';

export class UploadService extends GenericService<Upload> {

    public postDocument(contrat: Contrat, upload: Upload): Promise<Upload> {
        return this.post(`/documents/${contrat.id}`, upload);
    }

    public postDocumentChantier(chantier: Chantier, upload: Upload): Promise<Upload> {
        return this.post(`/documentchantiers/${chantier.id}`, upload);
    }

    public postDocumentChantierPhoto(chantier: Chantier, upload: Upload): Promise<Upload> {
        return this.post(`/documentchantiers/photo/${chantier.id}`, upload);
    }
    public postDocumentChauffeurChantier(chantier: Chantier, upload: Upload): Promise<Upload> {
        return this.post(`/documentchantiers/documentChauffeur/${chantier.id}`, upload);
    }
    public postDocumentPrestation(prestation: Prestation, upload: Upload): Promise<Upload> {
        return this.post(`/documentprestations/${prestation.id}`, upload);
    }
    public postDocumentPrestationCubage(prestation: Prestation, upload: Upload): Promise<Upload> {
        return this.post(`/documentprestationscubage/${prestation.id}`, upload);
    }

    public postDocumentCubage(cubage: CubageChantier, upload: Upload): Promise<Upload> {
        return this.post(`/documentcubages/${cubage.id}`, upload);
    }

    public postDocumentCubagePourChauffeur(cubage: CubageChantier, upload: Upload): Promise<Upload> {
        return this.post(`/documentcubagespourchauffeur/${cubage.id}`, upload);
    }

    public postDocumentCubageLivraison(cubage: CubageLivraison, upload: Upload): Promise<Upload> {
        return this.post(`/documentcubagelivraisons/${cubage.id}`, upload);
    }

    public postDocumentCubagePourClient(cubage: CubagePrestationEstimed, upload: Upload): Promise<Upload> {
        return this.post(`/documentcubagepourclients/${cubage.id}`, upload);
    }
}
