import {XHTTP} from '@/shared/xhttp';
import {PlateformeService} from '@/xhttp';
import {DateHelper} from "@/shared/utils";
import {BoisType} from './BoisType';
import {BoisQuality} from './BoisQuality';
import {BoisSize} from './BoisSize';
import {Marquage} from './Marquage';
import {Chantier} from './Chantier';
import {Purchase} from "./Purchase";
import {Tiers} from "./Tiers";
import {CubagePlateforme} from '@/models/CubagePlateforme';
import {PlateformeCase} from './PlateformeCase';

@XHTTP(PlateformeService, '/plateformes')
export class Plateforme {

	private _id: number;
	private _cubagePlateforme: CubagePlateforme;
	private _name: string;
	private _plateformeCases: PlateformeCase[] = [];

	/////////////
	// Getters //
	/////////////

	public get id(): number {
		return this._id;
	}

	public get cubagePlateforme(): CubagePlateforme {
		return this._cubagePlateforme;
	}

	public get name(): string {
		return this._name;
	}

	public get plateformeCases(): PlateformeCase[] {
		return this._plateformeCases;
	}

	/////////
	// Add //
	/////////
	
	/////////////
	// Setters //
	/////////////

	public set id(value: number) {
		this._id = value;
	}

	public set name(value: string) {
		this._name = value;
	}
	
	public set cubagePlateforme(value: CubagePlateforme) {
		this._cubagePlateforme = value;
	}

	public set plateformeCases(value: PlateformeCase[]) {
		this._plateformeCases = value;
	}

	///////////////
	// Serialize //
	///////////////

	public toJSON(): any {
		return {
			id            : this._id,
			cubagePlateforme  : this._cubagePlateforme ? this._cubagePlateforme.id : null,
			name	: this._name,
			plateformeCases: this._plateformeCases ? this._plateformeCases.map(r => r.toJSON()) : [],
		};
	}

	public fromJSON(data: any): this {
		this._id           = data.id
		this._cubagePlateforme    = data.cubagePlateforme ? (new CubagePlateforme()).fromJSON(data.cubagePlateforme) : null;
		this._name 		   = data.name;
		this._plateformeCases                = data.plateformeCases ? data.plateformeCases.map(r => (new PlateformeCase()).fromJSON(r)) : [];
		return this;
	}
}
