import {XHTTP} from '@/shared/xhttp';
import {ParcelleService} from '@/xhttp';
import {Prestation} from '@/models/Prestation';

@XHTTP(ParcelleService, '/parcelles')
export class Parcelle {
	
	private _id: number;
	private _numeroParcelle: string;
	private _numeroSection: string;
	private _numeroLot: string;
	private _zipCode: string;
	private _town: string;
	private _lieuxDit: string;
	private _prestation: Prestation;
	private _createdAt: Date;
	
	/////////////
	// Getters //
	/////////////
	
	public get id(): number {
		return this._id;
	}

	public get numeroParcelle(): string {
		return this._numeroParcelle;
	}
	
	public get numeroSection(): string {
		return this._numeroSection;
	}
	
	public get numeroLot(): string {
		return this._numeroLot;
	}
	
	public get zipCode(): string {
		return this._zipCode;
	}
	
	public get town(): string {
		return this._town;
	}
	
	public get lieuxDit(): string {
		return this._lieuxDit;
	}
	
	public get prestation(): Prestation {
		return this._prestation;
	}

	public get createdAt(): Date {
		return this._createdAt;
	}
	
	/////////////
	// Setters //
	/////////////

	public set numeroParcelle(value : string) {
		this._numeroParcelle = value;
	}
	
	public set numeroSection(value : string) {
		this._numeroSection = value;
	}
	
	public set numeroLot(value : string) {
		this._numeroLot = value;
	}
	
	public set zipCode(value : string) {
		this._zipCode = value;
	}
	
	public set town(value : string) {
		this._town = value;
	}

	public set lieuxDit(value: string) {
		this._lieuxDit = value;
	}
	
	public set prestation(value : Prestation) {
		this._prestation = value;
	}
	
	///////////////
	// Serialize //
	///////////////

	public toJSON(): any {
		return {
			id      : this._id,
			numeroParcelle: this._numeroParcelle,
			numeroSection : this._numeroSection,
			numeroLot     : this._numeroLot,
			zipCode       : this._zipCode,
			town          : this._town,
			lieuxDit      : this._lieuxDit,
		};
	}

	public fromJSON(data: any): this {
		this._id             = data.id;
		this._numeroParcelle = data.numeroParcelle;
		this._numeroSection  = data.numeroSection;
		this._numeroLot      = data.numeroLot;
		this._zipCode        = data.zipCode;
		this._town           = data.town;
		this._lieuxDit       = data.lieuxDit;
		return this;
	}
}
