import {BoisSize} from '@/models';

export default [
	{
		path: '/bois-size',
		name: 'boisSize',
		meta: {
			title: 'Liste des tailles de bois',
			granted: [ 'API_GET_BOISSIZE' ]
		},
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/BoisSize.vue')
	},
	{
		path: '/boisSize/edit/:id',
		name: 'boisSize-edit',
		props: {
			slotData: () => ({
				component: import(/* webpackChunkName: "dashboard" */ '../components/Crud/BoisSize/Edit.vue'),
				class: BoisSize
			}),
		},
		meta: {
			title: 'Edition d\'une taille de bois',
			granted: [ 'API_PUT_BOISSIZE' ]
		},
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/EditShow.vue')
	},
];