import {Debardeur} from '@/models';

export default [
	{
		path: '/debardeur',
		name: 'debardeur',
		meta: {
			title: 'Liste des debardeurs',
			granted: [ 'API_GET_DEBARDEUR' ]
		},
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/Debardeur.vue')
	},
	{
		path: '/debardeur/show/:id',
		name: 'debardeur-show',
		props: {
			slotData: () => ({
				component: import(/* webpackChunkName: "dashboard" */ '../components/Crud/Debardeur/Show.vue'),
				class: Debardeur
			}),
			editRoute: { name: 'debardeur-edit' },
		},
		meta: {
			title: 'Détails d\'un debardeur',
			granted: [ 'API_GET_DEBARDEUR' ]
		},
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/EditShow.vue')
	},
	{
		path: '/debardeur/edit/:id',
		name: 'debardeur-edit',
		props: {
			slotData: () => ({
				component: import(/* webpackChunkName: "dashboard" */ '../components/Crud/Debardeur/Edit.vue'),
				class: Debardeur
			}),
		},
		meta: {
			title: 'Edition d\'un debardeur',
			granted: [ 'API_PUT_DEBARDEUR' ]
		},
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/EditShow.vue')
	},
];
