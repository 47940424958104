import {XHTTP} from '@/shared/xhttp';
import {DateHelper} from "@/shared/utils";

export class PlateformeCase { 
	
	private _id: number;
	private _name: string; 

	/////////////
	// Getters //
	/////////////

	public get id(): number {
		return this._id;
	}

	public get name(): string {
		return this._name;
	}

	/////////////
	// Setters //
	/////////////

	public set name(value: string) {
		this._name = value;
	}

	///////////////
	// Serialize //
	///////////////

	public toJSON(): any {
		return {
			id            : this._id,
			name : this._name,
		};
	}
	
	public fromJSON(data: any): this {
		this._id           = data.id
		this._name  = data.name;
		return this;
	}
}
