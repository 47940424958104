import {XHTTP} from '@/shared/xhttp';
import {Prestation} from './Prestation';

export type M3TypePrice = 'M3'|'STERE';
export const M3TypePrice = {
	M3   : 'M3' as M3TypePrice,
	STERE: 'STERE' as M3TypePrice,
};


export class M3Price {
	
	private _id: number;
	private _price: number;
	private _description: string;
	private _typePrice: M3TypePrice = M3TypePrice.M3;

	/////////////
	// Getters //
	/////////////

	public get id(): number {
		return this._id;
	}

	public get price(): number {
		return this._price;
	}

	public get description(): string {
		return this._description;
	}

	public get typePrice(): M3TypePrice {
		return this._typePrice ? <any>this._typePrice.toString() : null;
	}

	/////////////
	// Setters //
	/////////////

	public set price(value: number) {
		this._price = value;
	}

	public set description(value: string) {
		this._description = value;
	}

	public set typePrice(value: M3TypePrice) {
		this._typePrice = value;
	}

	///////////////
	// Serialize //
	///////////////

	public toJSON(): any {
		return {
			id            : this._id,
			price       : this._price,
			description : this._description,
			typePrice : this._typePrice ? this._typePrice.toString() : null,
		};
	}
	
	public fromJSON(data: any): this {
		this._id           = data.id
		this._price        = data.price;
		this._description  = data.description;
		this._typePrice	   = data.typePrice;
		return this;
	}
}
