import {XHTTP} from '@/shared/xhttp';
import {LivraisonPlateformePourChauffeurService} from '@/xhttp';
import {DateHelper} from "@/shared/utils";
import {BoisType} from './BoisType';
import {BoisQuality} from './BoisQuality';
import {BoisSize} from './BoisSize';
import {Plateforme} from "./Plateforme";
import {Tiers} from "./Tiers";
import {PlateformeCase} from './PlateformeCase';
import {Marquage} from './Marquage';

@XHTTP(LivraisonPlateformePourChauffeurService, '/livraison-plateforme-pour-chauffeur')
export class LivraisonPlateformePourChauffeur {
	
	private _id: number;
	private _scierie: Tiers;
	private _plateforme: Plateforme;
	private _case: PlateformeCase;
	private _boisType: BoisType;
	private _boisQuality: BoisQuality;
	private _boisSize: BoisSize;
	private _marquage: Marquage;
	private _volume: number;
	private _remarques: string;

	/////////////
	// Getters //
	/////////////

	public get id(): number {
		return this._id;
	}

	public get scierie(): Tiers {
		return this._scierie;
	}

	public get plateforme(): Plateforme {
		return this._plateforme;
	}

	public get case(): PlateformeCase {
		return this._case;
	}

	public get boisType(): BoisType {
		return this._boisType;
	}

	public get boisQuality(): BoisQuality {
		return this._boisQuality;
	}

	public get boisSize(): BoisSize {
		return this._boisSize;
	}

	public get marquage(): Marquage {
		return this._marquage;
	}

	public get volume(): number {
		return this._volume;
	}

	public get remarques(): string {
		return this._remarques;
	}


	/////////////
	// Setters //
	/////////////

	public set scierie(value: Tiers) {
		this._scierie = value;
	}
	
	public set plateforme(value: Plateforme) {
		this._plateforme = value;
	}

	public set case(value: PlateformeCase) {
		this._case = value;
	}

	public set boisType(value: BoisType) {
		this._boisType = value;
	}

	public set boisQuality(value: BoisQuality) {
		this._boisQuality = value;
	}

	public set boisSize(value: BoisSize) {
		this._boisSize = value;
	}

	public set volume(value: number) {
		this._volume = value;
	}

	public set remarques(value: string) {
		this._remarques = value;
	}

	public set marquage(value: Marquage) {
		this._marquage = value;
	}

	///////////////
	// Serialize //
	///////////////

	public toJSON(): any {
		return {
			id            : this._id,
			scierie         : this._scierie ? this._scierie.id : null,
			plateforme: this._plateforme ? this._plateforme.id : null,
			case    : this._case ? this._case.id : null,
			boisType    : this._boisType ? this._boisType.id : null,
			boisQuality : this._boisQuality ? this._boisQuality.id : null,
			boisSize    : this._boisSize ? this._boisSize.id : null,
			marquage    : this._marquage ? this._marquage : null,
			volume : this._volume,
			remarques : this._remarques,
		};
	}
	
	public fromJSON(data: any): this {
		this._id           = data.id
		this._scierie      = data.scierie ? (new Tiers).fromJSON(data.scierie) : null;
		this._plateforme     = data.plateforme ? (new Plateforme()).fromJSON(data.plateforme) : null;
		this._case     = data.case ? (new PlateformeCase()).fromJSON(data.case) : null;
		this._boisType     = data.boisType ? (new BoisType()).fromJSON(data.boisType) : null;
		this._boisQuality  = data.boisQuality ? (new BoisQuality()).fromJSON(data.boisQuality) : null;
		this._boisSize     = data.boisSize ? (new BoisSize()).fromJSON(data.boisSize) : null;
		this._marquage     = data.marquage ? (new Marquage()).fromJSON(data.marquage) : null;
		this._volume  = data.volume;
		this._remarques  = data.remarques;
		return this;
	}
}
